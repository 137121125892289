import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { FormSubmitButton } from '../../Components/Button';
import { FormComponent } from '../../Components/FormCom/FormComponent';
import { TextInputField } from '../../Components/FormCom/FormField';
import { HideableTextFormField } from '../../Components/FormCom/HideableTextFormField';
import AuthContext from '../../Context/AuthContext';
import {
  IsignInData,
  SignInDataType,
  signinInitialValues,
  SignInSchema,
} from '../../Models/auth/SignIn';
import { publicFetch } from '../../utils';

export const SignInArea = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: SignInDataType) => {
    try {
      setLoadingSpinner(true);
      const res = await publicFetch.post('auth/business/login', values);
      const res_data: IsignInData = res.data.data;
      const { user, token } = res_data;

      setLoadingSpinner(false);
      authContext?.setAuthState({
        token: token,
        user: user,
      });
      navigate('/');
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.response);
      }
    }
  };

  return (
    <FormComponent
      initialValues={signinInitialValues}
      schema={SignInSchema}
      onSubmit={onSubmit}
    >
      <TextInputField
        label="User Name"
        name="username"
        type="email"
        placeholder="Email"
        className={'spanTwo margin'}
      />
      <HideableTextFormField
        label="Password"
        name="password"
        type="password"
        placeholder="Password"
        className={'spanTwo margin'}
      />

      <FormSubmitButton
        className="spanTwo"
        type="submit"
        showIcon={loadingSpinner}
      >
        Sign In
      </FormSubmitButton>
    </FormComponent>
  );
};
