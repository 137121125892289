import React, { useContext } from 'react';

import styled from '@emotion/styled';

import { ModalContext } from '../Context/ModalContext';

interface IModalContentWrapper {
  width: string;
  title: string;
  description?: string;
  children: React.ReactNode;
}
export const ModalContentWrapper: React.FC<IModalContentWrapper> = ({
  width,
  title,
  description,
  children,
}) => {
  const modalContext = useContext(ModalContext);
  const onClickCancel = (e: any) => {
    modalContext?.displayModal(false);
  };

  return (
    <Container width={width}>
      <>
        <div>
          <HeaderSection>
            <Label>{title}</Label>
            <Img src="/static/svg/cancel.svg" onClick={onClickCancel} />
          </HeaderSection>
          <Description>{description}</Description>
        </div>
        {children}
      </>
    </Container>
  );
};

interface IContainerWidth {
  width: string;
}

const Description = styled.div`
  margin-top: -0.3rem;
`;

const Container = styled.div<IContainerWidth>`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  /* border: 1px solid ${({ theme }) => theme.palette.brandGrey}; */
  width: ${({ width }) => width};
  gap: 2rem;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    width: 92vw;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.palette.blackPrimary};
  font-size: 1.125rem;
  font-weight: 700;
`;

const Img = styled.img`
  cursor: pointer;
  margin-top: 0.25rem;
  margin-right: -1rem;
`;
