import * as Yup from 'yup';

import { phoneRegex } from '../../utils';

export const OrdersSchema = Yup.object().shape({
  pickupLocation: Yup.string()
    .min(1, 'You need to select address from the drop down')
    .required('Please provide a pickup location'),
  deliveryLocation: Yup.string()
    .min(1, 'You need to select address from the drop down')
    .required('Please provide a delivery location'),
  estimatedCost: Yup.string(),
  //   sendersFullName: Yup.string().min(1, 'Please provide senders full name'),
  //   sendersPhoneNo: Yup.string().matches(
  //     phoneRegex,
  //     'Invalid Nigerian phone number'
  //   ),
  receiverFullName: Yup.string()
    .min(2, 'Too short')
    .required('Please provide receiver full name'),

  // assignRider: Yup.string()
  //   .min(2, 'Too short')
  //   .required('Please provide assigned rider'),

  receiverPhoneNo: Yup.string()
    .matches(phoneRegex, 'Invalid Nigerian phone number')
    .required('Receiver phone number is required'),
  packageType: Yup.string()
    .min(2, 'Too short')
    .required('Please specify package type'),
  // deliverDate: Yup.date().required('Please input date'),
  // deliverTime: Yup.string().required('Please input date'),

  //   additionalInfo: Yup.string().min(2, 'Too short'),
});

export type OrdersDataType = Yup.InferType<typeof OrdersSchema>;

type InitialValues = {
  pickupLocation: String;
  deliveryLocation: String;
  estimatedCost?: String;
  //   sendersFullName?: String;
  //   sendersPhoneNo?: String;
  receiverFullName: String;
  receiverPhoneNo: String;
  assignRider: string;
  deliverDate: string;
  deliverTime: string;
  packageType: String;
  //   additionalInfo?: String;
};

export const ordersInitialValues: InitialValues = {
  pickupLocation: '',
  deliveryLocation: '',
  estimatedCost: '',
  //   sendersFullName: '',
  //   sendersPhoneNo: '',
  receiverFullName: '',
  receiverPhoneNo: '',
  packageType: '',
  assignRider: '',
  deliverDate: '',
  deliverTime: '',
  //   additionalInfo: '',
};
