import styled from '@emotion/styled';

import Page from '../Components/Page';
import { PageTitle } from '../Components/PageTitle';
import Whatsapp from '../Components/Whatsapp';
import { Contact } from '../Sections/dashboard/support/Contact';
import { Faq } from '../Sections/dashboard/support/Faq';
import { GetHelp } from '../Sections/dashboard/support/GetHelp';

export default function Support() {
  return (
    <Page title="Darum | Support">
      <PageTitle title="Support">
        <Container>
          <Whatsapp />
          <Faq />
          <GetHelp />
          <Contact />
        </Container>
      </PageTitle>
    </Page>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 47.25rem;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
`;
