import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  tableFilterInitialValues,
  TableFilterSchema,
} from '../../Models/table/TableFilter';
import { FilterButton } from '../Button';
import { FormComponent } from '../FormCom/FormComponent';
import { TextInputField } from '../FormCom/FormField';
import { dateFilterPicker } from './TableConfig';

interface IonSubmitTableFilter {
  onSubmitTableFilter: any;
  filter: string;
  setFilter: (...args: any) => void;
}

export const FormFilter: React.FC<IonSubmitTableFilter> = ({
  onSubmitTableFilter,
  filter,
  setFilter,
}) => {
  const [showCustomFilterFields, setShowCustomFilterFields] = useState(false);
  const handleNormalDateFilterClick = (data: any) => {
    return setFilter(data.label);
  };

  return (
    <TableFilterContent>
      {dateFilterPicker.map((data) => (
        <div
          key={data.label}
          className={
            filter === data.label ? 'activeFilter highlight' : 'highlight'
          }
          onClick={() => handleNormalDateFilterClick(data)}
        >
          <div className="label">{data.label}</div>
          <div className="value">{data.value}</div>
        </div>
      ))}
      <div
        className="highlight"
        onClick={() => setShowCustomFilterFields(!showCustomFilterFields)}
      >
        Custom
      </div>
      {showCustomFilterFields && (
        <FilterFormArea>
          <FormComponent
            initialValues={tableFilterInitialValues}
            schema={TableFilterSchema}
            onSubmit={onSubmitTableFilter}
          >
            <TextInputField
              label="Start date"
              name="startDate"
              type="date"
              placeholder="Start date"
              className="spanTwo"
            />
            <TextInputField
              label="End date"
              name="endDate"
              type="date"
              placeholder="End date"
              className="spanTwo"
            />
            <FilterButtonSection className="spanTwo">
              <div></div>
              <FilterButton type="submit">filter</FilterButton>
            </FilterButtonSection>
          </FormComponent>
        </FilterFormArea>
      )}
    </TableFilterContent>
  );
};

const FilterButtonSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid red; */
  margin-bottom: 2rem;
`;

const FilterFormArea = styled.div`
  padding: 0.5rem 1rem;
`;

const TableFilterContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.secondaryText};
  width: 15.87rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  & > .activeFilter {
    background-color: ${({ theme }) => theme.palette.brandBackdrop};
    /* border: 2px solid red; */
  }

  & > .highlight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid red; */
    padding: 0.5rem 1rem;

    &:hover {
      background-color: ${({ theme }) => theme.palette.brandBackdrop};
    }
  }

  & > * > .value {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.tertiaryText};
  }
`;
