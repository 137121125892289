import React from 'react';

export const PaymentsIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 8H1.5M1.5 6.65L1.5 12.35C1.5 13.1901 1.5 13.6101 1.66349 13.931C1.8073 14.2132 2.03677 14.4427 2.31901 14.5865C2.63988 14.75 3.05992 14.75 3.9 14.75L14.1 14.75C14.9401 14.75 15.3601 14.75 15.681 14.5865C15.9632 14.4427 16.1927 14.2132 16.3365 13.931C16.5 13.6101 16.5 13.1901 16.5 12.35V6.65C16.5 5.80992 16.5 5.38988 16.3365 5.06902C16.1927 4.78677 15.9632 4.5573 15.681 4.41349C15.3601 4.25 14.9401 4.25 14.1 4.25L3.9 4.25C3.05992 4.25 2.63988 4.25 2.31901 4.41349C2.03677 4.5573 1.8073 4.78677 1.66349 5.06901C1.5 5.38988 1.5 5.80992 1.5 6.65Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
