import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { Button } from '../Button';
import CheveronLeft from '../Icons/CheveronLeft';
import CheveronRight from '../Icons/CheveronRight';
import { EmptyTableComponent } from './EmptyTableComponent';
import { TableLoadingSkeleton } from './TableLoadingSkeleton';

interface ImappingData {
  [key: string]: string;
}

interface ITable {
  showTableLoadingIcon: boolean;
  setShowTableLoadingIcon: (...arg: any) => void;
  page: number;
  endPage: number;
  mappingData?: ImappingData;
  tData: object[];
  tableHeader: string[];
  clickLeft: (...arg: any) => void;
  clickRight: (...arg: any) => void;
}

export const Table: React.FC<ITable> = ({
  showTableLoadingIcon,
  setShowTableLoadingIcon,
  page,
  endPage,
  mappingData,
  tData,
  clickLeft,
  clickRight,
  tableHeader,
}) => {
  const [tableHeaderLabels, setTableHeaderLabels] = useState<string[]>([]);
  const [tableData, setTableData] = useState<ITable | any>([]);

  useEffect(() => {
    setTableHeaderLabels(tableHeader);
    // eslint-disable-next-line
  }, []);

  const onClickLeft = () => {
    clickLeft();
    setShowTableLoadingIcon(true);
  };

  const onClickRight = () => {
    clickRight();
    setShowTableLoadingIcon(true);
  };

  useEffect(() => {
    setTableData(tData);
  }, [tData]);

  const config = tableHeaderLabels.map((tabelLabel) => {
    return { label: tabelLabel, render: (data: any) => data[tabelLabel] };
  });

  const renderedHeaders = config.map((column: any) => {
    const label = mappingData ? mappingData[column.label] : column.label;

    return (
      <th scope="col" key={column.label}>
        {label}
      </th>
    );
  });

  const renderedRows = tableData.map((data: any) => {
    const renderedCells = config.map((column: any) => {
      return <td key={column.label}>{column.render(data)}</td>;
    });

    return <tr key={JSON.stringify(data)}>{renderedCells}</tr>;
  });

  return (
    <>
      <StyledTable className={tableData[0] ? '' : 'emptyTable'}>
        <thead>
          <tr>{renderedHeaders}</tr>
        </thead>
        {showTableLoadingIcon ? (
          ''
        ) : (
          <tbody>{tableData[0] ? renderedRows : null}</tbody>
        )}
      </StyledTable>
      {showTableLoadingIcon ? <TableLoadingSkeleton /> : ''}
      {tableData[0] ? (
        <TablePaginatorArea>
          {`${page} of ${endPage}`}
          <TablePaginatorBtnCtrl>
            <Button disabled={page === 1 ? true : false} onClick={onClickLeft}>
              <CheveronLeft />
            </Button>
            <Button
              disabled={page === endPage ? true : false}
              onClick={onClickRight}
            >
              <CheveronRight />
            </Button>
          </TablePaginatorBtnCtrl>
        </TablePaginatorArea>
      ) : null}

      {tableData[0] || showTableLoadingIcon ? null : (
        <NoDataStatus>
          <EmptyTableComponent link="/orders">
            Book a dispatch
          </EmptyTableComponent>
        </NoDataStatus>
      )}
    </>
  );
};

// ---

const TablePaginatorArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const TablePaginatorBtnCtrl = styled.div`
  display: flex;
`;
// ---

const NoDataStatus = styled.div`
  display: grid;
  place-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
`;

const StyledTable = styled.table`
  font-size: 0.87rem;
  font-weight: 400;
  line-height: 2em;
  color: ${({ theme }) => theme.palette.secondaryText};
  background-color: white;
  border-collapse: collapse;
  border-radius: 0.5rem;
  overflow: hidden;
  outline: 1px solid ${({ theme }) => theme.palette.brandGrey};

  &.emptyTable {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media screen and (max-width: 820px) {
    width: 45rem;
  }

  & thead {
    text-align: left;
    font-weight: 700;
    background-color: ${({ theme }) => theme.palette.brandTableHeader};
  }

  & th,
  & td {
    padding: 1rem;
  }

  & tbody tr {
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.palette.brandGrey};
  }

  & tbody tr:last-child {
    border-bottom: 0px solid transparent;
  }

  & tbody tr:hover {
  }
`;
