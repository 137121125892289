import Page from '../Components/Page';
import { PageTitle } from '../Components/PageTitle';
import { TableComponent } from '../Components/TableCom';
import { PaymentsCard } from '../Sections/dashboard/payments/PaymentsCard';

export default function Payments() {
  return (
    <Page title="Darum | Payments">
      <PageTitle title="Payments">
        <PaymentsCard />
        <TableComponent
          tableBaseUrl="v1/wallet/history"
          tabelDescription="Payments History"
          tableHeader={['timestamp', 'id', 'channel', 'amount']}
          mappingData={{
            timestamp: 'Date',
            id: 'Order ID',
            channel: 'Channel',
            amount: 'Amount',
          }}
        />
      </PageTitle>
    </Page>
  );
}
