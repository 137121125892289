import React from 'react';

import styled from '@emotion/styled';

interface ISegmentsContainer {
  children: React.ReactNode;
  title: string;
}
export const SegmentContainer: React.FC<ISegmentsContainer> = ({
  children,
  title,
}) => {
  return (
    <Container>
      <Label>{title}</Label>
      <Content>{children}</Content>
    </Container>
  );
};

const Content = styled.div`
  /* border: 2px solid red; */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
  border-radius: 0.5rem;
  background-color: #ffffff;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    width: 100%;
  }
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
  /* border: 2px solid green; */
`;
