import * as Yup from 'yup';

export const SupportSchema = Yup.object().shape({
  contactMessage: Yup.string().min(1),
});

export type SupportDataType = Yup.InferType<typeof SupportSchema>;

type InitialValues = {
  contactMessage: string;
};

export const supportInitialValues: InitialValues = {
  contactMessage: '',
};
