import styled from '@emotion/styled';

export const CompanyLogo = () => {
  return <Img alt="darum" src="/static/svg/darum-logo.svg" />;
};

export const CompanyLetterLogo = () => {
  return <Img alt="darum" src="/static/svg/company-letter-logo.svg" />;
};
const Img = styled.img`
  max-height: 30px;
`;
