import React from 'react';

import styled from '@emotion/styled';
// import { GoogleMap, Marker } from 'react-google-maps';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';

import mapStyles from '../../../utils/mapStyles';

interface IMapSection {
  isMarkerShown: boolean;
}

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

export const MapSection: React.FC<IMapSection> = ({ isMarkerShown }) => {
  const position = { lat: 6.5001, lng: 3.3787 };
  return (
    <MapContainer>
      <GoogleMap
        mapContainerClassName={'mapContainerStyle'}
        zoom={10}
        options={options}
        center={position}
      >
        {isMarkerShown && (
          <Marker position={position} zIndex={100000} draggable={true}>
            <InfoWindow
              position={position}
              // onCloseClick={() => setActiveMarker(null)}
            >
              <div>{10}</div>
            </InfoWindow>
          </Marker>
        )}
      </GoogleMap>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  /* border: 2px solid green; */
  height: 20rem;

  & > *,
  & > * > * {
    &.mapArea {
      // max-width: 500px !important;
      height: 100vh;
      margin: 0 !important;
      padding: 0 !important;
      background: black;
      color: #fff;
      overflow: hidden;
      position: relative;
      margin: 0 auto 100px !important;
    }
    &.mapContainerStyle {
      width: 100%;
      height: 100%;
    }
  }
`;
