import React, { useContext } from 'react';

import styled from '@emotion/styled';

import LocationIcon from '../../../../Components/Icons/LocationIcon';
import { ModalContext } from '../../../../Context/ModalContext';

const EditAddressMenuCell = () => {
  const modalContext = useContext(ModalContext);
  const showModal = () => {
    modalContext?.displayModal(true);
  };
  return (
    <DropDownContentCell onClick={showModal}>
      <LocationIcon /> Edit Address
    </DropDownContentCell>
  );
};

export default EditAddressMenuCell;

const DropDownContentCell = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.brandBackdrop};
  }
`;
