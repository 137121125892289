export const dateFilterPicker = [
  {
    label: 'Today',
    description: '10 Aug', // TODO:: come back to correct this
    value: 'Todays Date',
  },
  {
    label: 'Last 7 days',
    description: '3 aug - 10 Aug', // TODO:: come back to correct this
    value: 'Last seven days',
  },
  {
    label: 'This Month',
    description: 'Aug', // TODO:: come back to correct this
    value: 'Last month',
  },
  {
    label: 'Last Month',
    description: 'Jul', // TODO:: come back to correct this
    value: 'Last month',
  },
  {
    label: 'All Time',
    description: '', // TODO:: come back to correct this
    value: null,
  },
];
