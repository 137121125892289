import React, { useContext } from 'react';

import styled from '@emotion/styled';

import { BareButton } from '../../../../Components/Button';
import { ModalContext } from '../../../../Context/ModalContext';
import { IShowAddLocation } from './interface';

export const AddressHeader: React.FC<IShowAddLocation> = ({
  showAddLocation,
  categoryName,
  itemLength = 0,
}) => {
  const modalContext = useContext(ModalContext);
  const showModal = () => {
    modalContext?.displayModal(true);
  };

  return (
    <Header>
      <Label>{categoryName}</Label>
      {showAddLocation && itemLength < 4 ? (
        <BareButton onClick={showModal}>+ Add another location</BareButton>
      ) : (
        ''
      )}
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 2px solid yellow; */
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
  /* border: 2px solid green; */
`;
