import { AuthLayout } from '../../Components/AuthLayout';
import Page from '../../Components/Page';
import { SignUpArea } from '../../Sections/auth/SignUpArea';

export default function SignUp() {
  return (
    <Page title="Darum | SignIn">
      <AuthLayout
        label="Already have an account?"
        linkTitle="Log In"
        linkPath="/account/signin"
        formHeaderTitle="Create an Account"
        formHeaderDescription="Provide the details"
      >
        <SignUpArea />
      </AuthLayout>
    </Page>
  );
}
