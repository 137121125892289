export const HOME = '/';
export const ORDERS = 'orders';
export const PAYMENTS = 'payments';
export const NOTIFICATION = 'notifications';
export const TOKENS = 'tokens';
export const SETTINGS = 'settings';
export const SUPPORT = 'support';
export const ACCOUNT = 'account';
export const SIGNUP = 'signup';
export const SIGNIN = 'signin';
export const VERIFY_OTP = 'verify-otp';
export const RESET_PASSWORD = 'reset-password';
export const RESET_PASSWORD_VERIFY = 'reset-password/verify';
export const NOTFOUND = '404';
