import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

interface IAuthLayout {
  children?: any;
  label?: string;
  linkTitle?: string;
  linkPath?: string;
  formHeaderTitle?: string;
  formHeaderDescription?: string | React.ReactNode;
  forgotPassword?: boolean;
  headerImgSrc?: string;
}

export const AuthLayout: React.FC<IAuthLayout> = ({
  children,
  label,
  linkTitle,
  linkPath,
  formHeaderTitle,
  formHeaderDescription,
  forgotPassword,
  headerImgSrc,
}) => {
  return (
    <AuthLayoutContainer>
      <FormArea>
        <FormAreaHeader>
          <CompanyLogo src="/static/img/darum-logo.png" />
          <LinkArea>
            <LinkSection>
              <LinkSectionLabel>{label}</LinkSectionLabel>{' '}
              <Link to={linkPath || '/'}>{linkTitle}</Link>{' '}
            </LinkSection>
          </LinkArea>
        </FormAreaHeader>
        <FormSection>
          {headerImgSrc && <FormSectionHeaderImg src={headerImgSrc} />}
          <FormSectionHeader>
            <FormSectionHeaderTitle>{formHeaderTitle}</FormSectionHeaderTitle>
            <FormSectionHeaderDescription>
              {formHeaderDescription}
            </FormSectionHeaderDescription>
          </FormSectionHeader>
          <FormBody>{children}</FormBody>
          {forgotPassword && (
            <ForgotPasswordLink to="/account/reset-password">
              Forgot password
            </ForgotPasswordLink>
          )}
        </FormSection>
      </FormArea>
      <ImgArea>
        <ImgContainer>
          <Img src="/static/svg/auth-backdrop.svg" />
        </ImgContainer>
      </ImgArea>
    </AuthLayoutContainer>
  );
};

const AuthLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 54fr 46fr;
  height: 100vh;
  /* border: 2px solid blue; */
  overflow: hidden;

  @media (max-width: 480px) {
    display: flex;
    height: 100%;
    /* border: 2px solid yellow; */
    padding: 2rem 2.12rem;
    flex-direction: column;
  }
`;

const FormSectionHeaderImg = styled.img`
  width: 16rem;
  align-self: center;
`;

const FormBody = styled.div`
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const FormSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const FormSectionHeaderTitle = styled.div`
  display: flex;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
`;

const FormSectionHeaderDescription = styled.div`
  display: flex;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondaryText};

  margin-left: 15%;
  margin-right: 15%;
`;

const FormAreaHeader = styled.div`
  /* border: 2px solid black; */
  display: flex;
  justify-content: space-between;
`;

const CompanyLogo = styled.img`
  width: 7.86rem;
`;

const LinkArea = styled.div`
  /* border: 2px solid green; */
  display: flex;
  gap: 1rem;

  @media (max-width: 480px) {
    gap: 0px;
  }
`;

const LinkSection = styled.div`
  color: ${({ theme }) => theme.palette.tertiaryText};
  font-weight: 400;
`;

const LinkSectionLabel = styled.span`
  @media (max-width: 480px) {
    display: none;
  }
`;

const Link = styled(RouterLink)`
  /* border: 2px solid red; */
  text-decoration: none;
  color: ${({ theme }) => theme.palette.brandPrimary};
  font-weight: 700;
`;

const ForgotPasswordLink = styled(Link)`
  text-align: center;
`;

const FormArea = styled.div`
  /* border: 2px solid red; */
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 480px) {
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    margin: 0px auto;
  }
`;

const FormSection = styled.div`
  margin: auto 2.87rem;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 480px) {
    margin: 0;
  }
`;

const ImgArea = styled.div`
  /* border: 2px solid green; */
  background-color: ${({ theme }) => theme.palette.brandBackdrop};
  padding: 10.6rem 0 0 2.69rem;

  @media (max-width: 480px) {
    display: none;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  object-fit: cover;
  object-position: left top;
`;
