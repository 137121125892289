import toast from 'react-hot-toast';

import { FormSubmitButton } from '../../../Components/Button';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import { TextInputField } from '../../../Components/FormCom/FormField';
import { ModalContentWrapper } from '../../../Components/ModalContentWrapper';
import {
  WalletDataType,
  walletInitialValues,
  WalletSchema,
} from '../../../Models/payments/Payments';
import { fundWallet } from '../../../redux/slices/fundWalletSlice';
import { useAppDisPatch, useAppSelector } from '../../../redux/store';

const FundWalletModalFormContent = () => {
  const dispatch = useAppDisPatch();
  const { walletBalance } = useAppSelector((state) => state.wallet);

  const onSubmit = async (values: WalletDataType, actions: any) => {
    // alert(JSON.stringify(values));
    try {
      const data = await dispatch(fundWallet(+values.amount));
      const paystackUrl = data.payload.data;
      actions.resetForm(walletInitialValues);
      window.open(paystackUrl, '_blank');
    } catch (error: any) {
      const { data } = error.response;
      toast.error(data.message);
    }
  };

  return (
    <ModalContentWrapper title="Fund Wallet" width={'28rem'}>
      <FormComponent
        initialValues={walletInitialValues}
        schema={WalletSchema}
        onSubmit={onSubmit}
      >
        <TextInputField
          label="Amount"
          name="amount"
          type="text"
          placeholder="Enter Amount"
          className={'spanTwo'}
        />
        <div>
          Wallet Balance :{' '}
          {walletBalance?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN',
          })}
        </div>

        <FormSubmitButton className="right mt1" type="submit">
          Fund Wallet
        </FormSubmitButton>
      </FormComponent>
    </ModalContentWrapper>
  );
};

export default FundWalletModalFormContent;
