import React, { useContext } from 'react';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../../Components/Button';
import { ModalContentWrapper } from '../../../Components/ModalContentWrapper';
import { ModalContext } from '../../../Context/ModalContext';
import {
  deleteOrderFromStack,
  getOrderPrice,
} from '../../../redux/slices/ordersSlice';
import { useAppDisPatch } from '../../../redux/store';

interface ICancelOrderModalComponent {
  index: number;
  ordersCreated: number;
}

const CancelOrderModalComponent: React.FC<ICancelOrderModalComponent> = ({
  index,
  ordersCreated,
}) => {
  const dispatch = useAppDisPatch();
  const modalContext = useContext(ModalContext);

  const onSubmit = () => {
    dispatch(deleteOrderFromStack(index));
    dispatch(getOrderPrice());
    modalContext?.displayModal(false);
  };

  const onCancel = () => {
    modalContext?.displayModal(false);
  };

  return (
    <ModalContentWrapper title="Fund Wallet" width={'28rem'}>
      <div>
        Are you sure you want to delete drop-off{' '}
        {`${index + 1} of ${ordersCreated}?`}
      </div>
      <ButtonArea className="spanTwo">
        <FormSubmitButton
          className="secoundary right-col-1"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </FormSubmitButton>
        <FormSubmitButton
          className="left-col-2 cancel"
          type="button"
          onClick={onSubmit}
        >
          Yes, Delete
        </FormSubmitButton>
      </ButtonArea>
    </ModalContentWrapper>
  );
};

export default CancelOrderModalComponent;

const ButtonArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
`;
