import React from 'react';

const SignOutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.75L2.25 9M2.25 9L6 5.25M2.25 9H11.25M9 12.75C9 13.4475 9 13.7962 9.07667 14.0823C9.28472 14.8588 9.8912 15.4653 10.6677 15.6733C10.9538 15.75 11.3025 15.75 12 15.75H12.375C13.4234 15.75 13.9476 15.75 14.361 15.5787C14.9124 15.3504 15.3504 14.9124 15.5787 14.361C15.75 13.9476 15.75 13.4234 15.75 12.375V5.625C15.75 4.57663 15.75 4.05245 15.5787 3.63896C15.3504 3.08765 14.9124 2.64963 14.361 2.42127C13.9476 2.25 13.4234 2.25 12.375 2.25H12C11.3025 2.25 10.9538 2.25 10.6677 2.32667C9.8912 2.53472 9.28472 3.1412 9.07667 3.91766C9 4.20378 9 4.55252 9 5.25"
        stroke="#867C79"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignOutIcon;
