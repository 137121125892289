import * as Yup from 'yup';

export const WalletSchema = Yup.object().shape({
  amount: Yup.string().required('Enter an amount'),
});

export type WalletDataType = Yup.InferType<typeof WalletSchema>;

type InitialValues = {
  amount: String;
};

export const walletInitialValues: InitialValues = {
  amount: '',
};
