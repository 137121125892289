import { FormSubmitButton } from '../../../Components/Button';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import { TextAreaInputField } from '../../../Components/FormCom/FormField';
import {
  SupportDataType,
  supportInitialValues,
  SupportSchema,
} from '../../../Models/supports/Support';
import { SegmentContainer } from './SegmentContainer';

export const GetHelp = () => {
  const onSubmit = (values: SupportDataType) => {
    // alert(JSON.stringify(values));
  };
  return (
    <SegmentContainer title="How can we help">
      <FormComponent
        initialValues={supportInitialValues}
        schema={SupportSchema}
        onSubmit={onSubmit}
      >
        <TextAreaInputField
          label="Send a message"
          name="phoneNumber"
          type="textArea"
          placeholder="Please tell us how best we can help you"
          className={'spanTwo'}
        />
        <FormSubmitButton className="right" type="submit">
          Send Messages
        </FormSubmitButton>
      </FormComponent>
    </SegmentContainer>
  );
};
