import { useState } from 'react';
import toast from 'react-hot-toast';

import styled from '@emotion/styled';

// import { AuthErrorBox } from '../../../components/AuthErrorBox';
import { FormSubmitButton } from '../../../Components/Button';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import { TextInputField } from '../../../Components/FormCom/FormField';
import {
  WalletDataType,
  walletInitialValues,
  WalletSchema,
} from '../../../Models/payments/Payments';
import { fundWallet } from '../../../redux/slices/fundWalletSlice';
import { useAppDisPatch } from '../../../redux/store';

export const FundWalletForm = () => {
  const dispatch = useAppDisPatch();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: WalletDataType, actions: any) => {
    try {
      setLoadingSpinner(true);
      var windowReference = window.open();
      const data = await dispatch(fundWallet(+values.amount));
      const paystackUrl = data.payload.data;
      actions.resetForm(walletInitialValues);
      setLoadingSpinner(false);
      windowReference!.location = paystackUrl;
      // window.open(paystackUrl, '_blank');
    } catch (error: any) {
      setLoadingSpinner(false);
      const { data } = error.response;
      toast.error(data.message);
    }
  };

  return (
    <Container>
      <Label>Fund Wallet</Label>
      <FormComponent
        initialValues={walletInitialValues}
        schema={WalletSchema}
        onSubmit={onSubmit}
        className="horizontalForm"
      >
        <TextInputField
          label="Amount"
          name="amount"
          type="text"
          placeholder="Enter Amount"
          className="horizontalForm"
        />
        <FormSubmitButton
          type="submit"
          className="noMargin"
          showIcon={loadingSpinner}
        >
          Confirm
        </FormSubmitButton>
      </FormComponent>
    </Container>
  );
};

const Label = styled.div`
  color: ${({ theme }) => theme.palette.primaryText};
  font-weight: 700;
`;

const Container = styled.div`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
  padding: 2rem 1.25rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
