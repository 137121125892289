import React from 'react';

import styled from '@emotion/styled';

export const TableLoadingSkeleton = () => {
  return (
    <EmptyTable>
      <img src="/static/svg/tableLoadingSkeleton.svg" alt="table loading" />
    </EmptyTable>
  );
};

const EmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1.8rem;

  & > * {
    text-align: center;
    width: 100%;
  }
`;
