import React, { useContext } from 'react';

// import toast from 'react-hot-toast';
import styled from '@emotion/styled';

// import { FormSubmitButton } from '../../../Components/Button';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import { HideableandCopyableTextFormField } from '../../../Components/FormCom/HideableandCopyableTextFormField';
import AuthContext from '../../../Context/AuthContext';
// import FetchContext from '../../../Context/FetchContext';
import { apiInitialValues, ApiSchema } from '../../../Models/settings/Api';

export const Api = () => {
  const authContext = useContext(AuthContext);
  // const fetchContext = useContext(FetchContext);
  // const [loadingSpinner, setLoadingSpinner] = useState(false);

  // const onSubmit = async (values: ApiDataType) => {
  //   try {
  //     setLoadingSpinner(true);
  //     await fetchContext?.authAxios.post('v1/business/new-api-key');
  //     setLoadingSpinner(false);
  //   } catch (error: any) {
  //     setLoadingSpinner(false);
  //     if (error.response) {
  //       const { data } = error.response;
  //       toast.error(data.message);
  //     } else {
  //       toast.error(error.message);
  //     }
  //   }
  // };
  return (
    <Container>
      <Label>Generate Token</Label>

      <FormComponent
        initialValues={apiInitialValues}
        schema={ApiSchema}
        // onSubmit={onSubmit}
        onSubmit={() => ''}
      >
        <HideableandCopyableTextFormField
          label="Api Key"
          name="apiKey"
          type="text"
          placeholder="Api key"
          className={'spanTwo '}
          apiKey={authContext?.authState.token!}
        />
        {/* 
        <FormSubmitButton
          className="spanTwo"
          type="submit"
          showIcon={loadingSpinner}
        >
          Generate new keys
        </FormSubmitButton> */}
      </FormComponent>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 1.25rem;
  width: 31.25rem;
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
  /* border: 2px solid green; */
`;
