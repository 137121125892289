import * as Yup from 'yup';

export const TableFilterSchema = Yup.object().shape({
  startDate: Yup.date(),
  endDate: Yup.date(),
});

export type TableFilterDataType = Yup.InferType<typeof TableFilterSchema>;

type InitialValues = {
  startDate: string;
  endDate: string;
};

export const tableFilterInitialValues: InitialValues = {
  startDate: '',
  endDate: '',
};
