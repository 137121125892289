import addressReducer from './slices/addressSlice';
import funsWalletReducer from './slices/fundWalletSlice';
import ordersReducer from './slices/ordersSlice';
import walletReducer from './slices/walletSlice';
import webhookReducer from './slices/webhookSlice';

export const reducers = {
  orders: ordersReducer,
  wallet: walletReducer,
  funsWallet: funsWalletReducer,
  webhook: webhookReducer,
  address: addressReducer,
};
