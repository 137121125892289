import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import styled from '@emotion/styled';

import { Card } from '../../../Components/Card';
import FetchContext from '../../../Context/FetchContext';
import { useAppSelector } from '../../../redux/store';
import { truncateString } from '../../../utils';

export const ActionCards = () => {
  const fetchContext = useContext(FetchContext);
  const { walletBalance } = useAppSelector((state) => state.wallet);
  const { defaultAddress } = useAppSelector((state) => state.address);
  const [totalDelivery, setTotalDelivery] = useState('...');

  useEffect(() => {
    const getTotalOrders = async () => {
      try {
        const resData = await fetchContext?.authAxios.get(`v1/request/total`);
        const totalDeliveries = resData.data.data;
        setTotalDelivery(totalDeliveries);
      } catch (error: any) {
        // console.log(error);
        const { data } = error.response;
        toast.error(data.message);
      }
    };
    getTotalOrders();
    // eslint-disable-next-line
  }, []);

  let address = '';
  if (
    defaultAddress[0]?.secondaryText === undefined ||
    defaultAddress[0]?.primaryText === undefined
  ) {
    address = '...';
  } else {
    address = `${defaultAddress[0]?.secondaryText}, ${defaultAddress[0]?.primaryText}`;
  }

  return (
    <CardList>
      <Card
        label="Wallet Balance"
        focusData={`${walletBalance?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN',
        })}`}
        actionLabel="Fund Wallet"
        iconSrc="/static/svg/wallet.svg"
        to="/payments"
      />
      <Card
        label="Total Delivery"
        focusData={totalDelivery}
        // actionLabel="View History"
        iconSrc="/static/svg/delivery.svg"
        to="/orders"
      />
      <Card
        label="Address"
        descriptionData={truncateString(address, 110)}
        iconSrc="/static/svg/location-bg.svg"
        to="/tokens"
      />
    </CardList>
  );
};

const CardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
  }
`;
