import { useEffect } from 'react';

import styled from '@emotion/styled';

import { OrdersProvider } from '../../../Context/OrdersContext';
import {
  resetOrdersStack,
  resetOrderSuccessfulSubmitState,
} from '../../../redux/slices/ordersSlice';
import { fetchBalance } from '../../../redux/slices/walletSlice';
import { useAppDisPatch, useAppSelector } from '../../../redux/store';
import { MapSection } from './MapSection';
import { OrderFormSection } from './OrderFormSection';
import { OrderSummaryCardController } from './OrderSummaryCardController';

interface IOrderSection {
  setShowBookADispatchPage: (...args: any) => void;
}

export const OrderSection: React.FC<IOrderSection> = ({
  setShowBookADispatchPage,
}) => {
  const dispatch = useAppDisPatch();
  const { ordersCreated, ordersEditMode, orderSuccessfulSubmitState } =
    useAppSelector((state) => state.orders);

  useEffect(() => {
    if (orderSuccessfulSubmitState) {
      dispatch(resetOrderSuccessfulSubmitState());
      dispatch(resetOrdersStack());
      // !use to fetch the balance once more
      dispatch(fetchBalance());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Wrapper>
      <Banner>
        <img src="/static/svg/helmet.svg" alt="order warning" />
        Please note that orders placed before 10am would be fulfilled on or
        within 10am, after 10am our next pick-up & drop-of occurs on or within
        2pm-3pm. Orders placed after 4pm would be delivered early the next day.
      </Banner>
      <Container>
        <OrdersProvider>
          <OrderFormSection
            setShowBookADispatchPage={setShowBookADispatchPage}
          />
        </OrdersProvider>
        <PreviewArea>
          <MapSection isMarkerShown={true} />
          {ordersCreated > 0 && !ordersEditMode && (
            <OrderSummaryCardController
              setShowBookADispatchPage={setShowBookADispatchPage}
            />
          )}
        </PreviewArea>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  gap: 1rem;
`;

const Banner = styled.div`
  display: flex;
  gap: 1rem;
  background-color: ${({ theme }) => theme.palette.warningBackdrop};
  padding: 1rem;
  align-items: center;

  @media screen and (max-width: 480px) {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 64fr 36fr;
  gap: 1.5rem;

  @media screen and (max-width: 480px) {
    display: flex;
  }
`;

const PreviewArea = styled.div`
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;
