import * as Yup from 'yup';

import { phoneRegex } from '../../utils';

export const SignUpSchema = Yup.object().shape({
  businessName: Yup.string()
    .min(2, 'Too short')
    .required('Please provide a full name'),
  email: Yup.string().email().required('Email is required'),
  phone: Yup.string()
    .matches(phoneRegex, 'Invalid Nigerian phone number')
    .required('Phone number is required'),
  password: Yup.string().min(8).required('Please provide a your password'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export type SignUpDataType = Yup.InferType<typeof SignUpSchema>;

type InitialValues = {
  businessName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
};

export const initialValues: InitialValues = {
  businessName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
};

// interface IUser {
//   id: number;
//   key: string;
// }

// interface IUser {
//   id: number;
//   userId: string;
//   fullName: string;
//   email: string;
//   password: string;
//   phoneNo: string;
//   gender: string;
//   token?: string;
//   deviceOS?: string;
//   paymentMethodId: number;
//   paymentType: number;
//   createdAt: string;
//   updatedAt: string;
//   verified: boolean;
//   type: number;
//   forgetCode: string;
//   key: string;
//   promoCodeId: number;
//   referralCod?: string;
//   avatar?: string;
// }

// interface IWebhook {
//   startTrip?: string;
//   endTrip?: string;
// }

// interface IsignInData {
//   key: string;
//   user: IUser;
//   webhook: IWebhook;
//   status: string;
//   message: string;
// }

// interface IsignInData {
//   user: IUser;
//   webhook: IWebhook;
//   status: string;
//   message: string;
//   verificationCode?: string;
// }
