import React, { useEffect } from 'react';

import styled from '@emotion/styled';

import { Banner, SuccessBanner } from '../../../Components/Banner';
import WarningIcon from '../../../Components/Icons/WarningIcon';
import { getOrderPrice } from '../../../redux/slices/ordersSlice';
import { fetchBalance } from '../../../redux/slices/walletSlice';
import { useAppDisPatch, useAppSelector } from '../../../redux/store';
import { CompondOrderSummaryCard } from './CompondOrderSummaryCard';
import DispatchSummaryOrderSubmitButtons from './DispatchSummaryOrderSubmitButtons';

interface IDispatchSummary {
  setShowBookADispatchPage: (...args: any) => void;
}

export const DispatchSummary: React.FC<IDispatchSummary> = ({
  setShowBookADispatchPage,
}) => {
  const dispatch = useAppDisPatch();
  const {
    ordersStack,
    ordersCreated,
    totalEstimatedPrice,
    orderSuccessfulSubmitState,
  } = useAppSelector((state) => state.orders);
  const { walletBalance } = useAppSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(getOrderPrice());
    dispatch(fetchBalance());
  }, [dispatch]);

  useEffect(() => {
    if (ordersCreated === 0) {
      setShowBookADispatchPage(true);
    }
  }, [ordersCreated, setShowBookADispatchPage]);

  const orderSubmitClause = walletBalance >= totalEstimatedPrice;
  return (
    <Container className={ordersCreated === 1 ? 'singleOrder' : ''}>
      {!orderSuccessfulSubmitState ? (
        <Banner
          totalEstimatedPrice={totalEstimatedPrice?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN',
          })}
          walletBalance={walletBalance.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN',
          })}
        />
      ) : (
        <SuccessBanner />
      )}

      {!orderSubmitClause && (
        <PricingWarningBanner>
          <WarningIcon /> You have insufficient funds in your wallet, kindly
          fund your account to book a dispatch
        </PricingWarningBanner>
      )}
      <SummaryTile className={ordersCreated === 1 ? 'singleOrder' : ''}>
        {ordersStack.map((data, index) => (
          <CompondOrderSummaryCard
            {...data}
            index={index}
            ordersCreated={ordersCreated}
            key={index}
            setShowBookADispatchPage={setShowBookADispatchPage}
          />
        ))}
      </SummaryTile>
      <DispatchSummaryOrderSubmitButtons
        setShowBookADispatchPage={setShowBookADispatchPage}
      />
    </Container>
  );
};

const PricingWarningBanner = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${({ theme }) => theme.palette.warningPrimary};
`;

const Container = styled.div`
  /* border: 2px solid yellow; */
  width: 55rem;
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 55rem;

  & > div {
    width: 100%;
  }

  &.singleOrder {
    width: 30.5rem;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 3rem;
    /* display: flex;
    flex-direction: column; */
  }
`;

const SummaryTile = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 0.5rem);
  /* border: 2px solid red; */

  &.singleOrder {
    width: 100%;
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
