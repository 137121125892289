import React from 'react';

import styled from '@emotion/styled';

import { AddressHeader } from './AddressHeader';
import { AddressPlace } from './AddressPlace';
import { IAddressPlace } from './interface';

export const AddressCard: React.FC<IAddressPlace> = ({
  defaultAddress,
  showAddLocation,
  addressData,
  categoryName,
}) => {
  return (
    <Container>
      <AddressHeader
        showAddLocation={showAddLocation}
        categoryName={categoryName}
        itemLength={addressData.length}
      />
      <AddressPlace defaultAddress={defaultAddress} addressData={addressData} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
  border-radius: 0.5rem;
  background-color: #ffffff;
  /* border: 2px solid red; */
  width: 47.25rem;

  @media (max-width: 480px) {
    width: 100%;
    /* border: 2px solid yellow; */
  }
`;
