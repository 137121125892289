import * as Yup from 'yup';

export const WebhookSchema = Yup.object().shape({
  webhook: Yup.string().url(),
});

export type WebhookDataType = Yup.InferType<typeof WebhookSchema>;

type InitialValues = {
  webhook: string;
};

export const webhookInitialValues: InitialValues = {
  webhook: 'https://eminah.com',
};
