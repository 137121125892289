import { useContext } from 'react';
import ReactDom from 'react-dom';

import styled from '@emotion/styled';

import { ModalContext } from '../Context/ModalContext';

interface IModal {
  children: React.ReactNode;
}

export const Modal: React.FC<IModal> = ({ children }) => {
  const modalRoot = document.getElementById('modal-root');
  const modalContext = useContext(ModalContext);

  return ReactDom.createPortal(
    <ModalContainer className={modalContext?.showModal ? '' : 'closeModal'}>
      {children}
    </ModalContainer>,
    modalRoot!
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  isolation: isolate;
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  place-content: center;

  &.closeModal {
    display: None;
  }
`;
