import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

interface ICountDownTimer {
  initialTime: number;
  setShowResendOtpButton: (...args: any) => void;
}

export const CountDownTimer: React.FC<ICountDownTimer> = ({
  initialTime,
  setShowResendOtpButton,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if (timeRemaining === 0) {
      setShowResendOtpButton(true);
    }
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timeRemaining]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return <TimerText>{formatTime(timeRemaining)}</TimerText>;
};

const TimerText = styled.span`
  color: ${({ theme }) => theme.palette.brandPrimary};
  font-weight: 700;
  width: 3rem;
  text-align: left;
  /* border: 2px solid red; */
`;
