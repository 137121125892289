import styled from '@emotion/styled';

import BackArrowIcon from './Icons/BackArrowIcon';
import { SpinningIcon } from './SpinningIcon';

interface IGoBackButton {
  btnCallBack?: (...args: any) => void;
}
export const GoBackButton: React.FC<IGoBackButton> = ({
  btnCallBack = () => '',
}) => {
  return (
    <BackButton onClick={btnCallBack}>
      <BackArrowIcon />
      Go back
    </BackButton>
  );
};
const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;
  font-size: 0.875rem;

  @media screen and (max-width: 480px) {
    font-size: 0.65rem;
  }
`;

export const AddLocationBtn = styled.div``;

export const BareButton = styled.button`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.brandPrimary};
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  width: fit-content;

  &:disabled {
    cursor: not-allowed;
  }
`;

interface IFormSubmitButton {
  iconColor?: string;
  iconSize?: string;
  showIcon?: boolean;
  children: string;
  width?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  // onClick?: (...args: any) => any;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const FormSubmitButton: React.FC<IFormSubmitButton> = ({
  iconColor,
  iconSize,
  showIcon,
  children,
  className,
  type,
  disabled,
  onClick,
  width,
}) => {
  return (
    <FormSubmitBtn
      type={type}
      disabled={disabled}
      className={className ? className : ''}
      onClick={onClick}
    >
      {/* <div></div> */}
      {children}
      {showIcon ? (
        <SpinningIcon color={iconColor || 'white'} size={Number(iconSize)} />
      ) : (
        ''
      )}
    </FormSubmitBtn>
  );
};

export const FormSubmitBtn = styled.button`
  background-color: ${({ theme }) => theme.palette.brandPrimary};
  padding: 1rem 2rem;
  font-weight: 800;
  color: white;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 2rem 15% 0;
  /* for the spinner */
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 48px;

  &.secoundary {
    background-color: white;
    color: ${({ theme }) => theme.palette.tertiaryText};
    border: 1px solid ${({ theme }) => theme.palette.tertiaryText};
  }

  &.noMargin {
    margin: 0;
    padding: 0.85rem 2rem;
    height: 48px;
  }

  &.right {
    margin: 0;
    grid-column: 2/3;
    margin-left: auto;
    width: 220px;
  }

  &.right-col-1 {
    margin: 0;
    grid-column: 1/2;
    width: fit-content;
    margin-left: auto;
  }

  &.left-col-2 {
    margin: 0;
    grid-column: 2/3;
    width: fit-content;
    margin-right: auto;
  }

  &.mt1 {
    margin-top: 1rem;
  }

  &.margin {
    margin: auto;
  }
  &.mt4 {
    margin-top: 4rem;
  }

  &.spanTwo {
    grid-column: 1/3;
  }

  &.cancel {
    background-color: ${({ theme }) => theme.palette.errorPrimary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.brandInactive};
    cursor: not-allowed;
  }

  /* &:hover {
    background-color: ${({ theme }) => theme.palette.brandPrimary};
  } */
`;

export const QuickActionButton = styled.button`
  background-color: ${({ theme }) => theme.palette.brandPrimary};
  /* background-color: ${({ theme }) => theme.palette.brandInactive}; */
  padding: 1rem;
  font-weight: 800;
  color: white;
  border-radius: 0.25rem;
  border: 2px solid transparent;
  cursor: pointer;
  /* width: ; */
  /* margin-left: 15%; */
  margin: 2rem 15% 0;

  /* &:hover {
    background-color: ${({ theme }) => theme.palette.brandPrimary};
  } */
`;

export const FilterButton = styled.button`
  background-color: ${({ theme }) => theme.palette.brandPrimary};
  /* background-color: ${({ theme }) => theme.palette.brandInactive}; */
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  border-radius: 0.25rem;
  border: 2px solid transparent;
  cursor: pointer;
  /* width: ; */
  /* margin-left: 15%; */
  /* margin: 2rem 15% 0; */

  /* &:hover {
  background-color: ${({ theme }) => theme.palette.brandPrimary};
} */
`;

export const SubmitButton: React.FC<IButton> = ({
  iconColor,
  iconSize,
  showIcon,
  children,
  width,
}) => {
  return (
    <StyledButton width={width} type="submit">
      {children}
      {showIcon ? (
        <SpinningIcon color={iconColor || '#e4e4e4'} size={Number(iconSize)} />
      ) : (
        ''
      )}
    </StyledButton>
  );
};

export const Button: React.FC<IIconButton> = ({
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <IconButton
      type="submit"
      disabled={disabled}
      onClick={onClick}
      className={disabled === true ? 'not-allowed' : ''}
    >
      {children}
    </IconButton>
  );
};

export const StyledButton = styled.button<IStyledButton>`
  width: ${({ width }) => (width ? width : '100%')};
  line-height: 2rem;
  font-weight: bold;
  padding: 0.68rem 2rem;
  text-transform: capitalize;
  border-radius: 0.5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.palette.tertiaryBgColor};
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.palette.primaryBorderColor};
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  margin-top: 1rem;
  & > * {
    color: ${({ textColor, theme }) =>
      textColor ? textColor : theme.palette.primaryBorderColor};
  }
  border: 2px solid ${({ theme }) => theme.palette.tertiaryBgColor};

  &:focus {
    outline: none;
    border: 2px solid #e9dacc;
  }

  @media screen and (max-width: 540px) {
    & {
      line-height: 1.2rem;
      padding: 0.5rem 1.5rem;
    }
  }
`;

export const IconButton = styled.button<IStyledButton>`
  /* width: fit-content; */
  /* width: 3rem; */
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  /* border: 2px solid red; */
  padding: 0;

  &:focus {
    outline: none;
  }

  &.not-allowed {
    cursor: not-allowed;
  }

  &.not-allowed > svg {
    cursor: not-allowed;
  }
`;

interface IIconButton {
  disabled?: boolean;
  onClick?: (...args: any) => void;
  iconColor?: string;
  iconSize?: string;
  showIcon?: boolean;
  children: any;
  width?: string;
}

interface IButton {
  iconColor?: string;
  iconSize?: string;
  showIcon?: boolean;
  children: string;
  width?: string;
}

interface IStyledButton {
  width?: string;
  bgColor?: string;
  bgHoverColor?: string;
  textColor?: string;
  textHoverColor?: string;
  border?: string;
  cursor?: string;
  fontSize?: string;
}
