import * as Yup from 'yup';

export const UpdatePaswdSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8)
    .required('Please provide a your current password'),
  newPassword: Yup.string()
    .required()
    .required('Please provide a your new password'),
  confirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
});

export type UpdatePaswdDataType = Yup.InferType<typeof UpdatePaswdSchema>;

type InitialValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const updatePaswdInitialValues: InitialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};
