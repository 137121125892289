import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" fill="white" />
      <path
        d="M4.16675 11.9046C2.62395 12.5852 1.66675 13.5337 1.66675 14.5827C1.66675 16.6538 5.39771 18.3327 10.0001 18.3327C14.6025 18.3327 18.3334 16.6538 18.3334 14.5827C18.3334 13.5337 17.3762 12.5852 15.8334 11.9046M15.0001 6.66602C15.0001 10.0524 11.2501 11.666 10.0001 14.166C8.75008 11.666 5.00008 10.0524 5.00008 6.66602C5.00008 3.90459 7.23866 1.66602 10.0001 1.66602C12.7615 1.66602 15.0001 3.90459 15.0001 6.66602ZM10.8334 6.66602C10.8334 7.12625 10.4603 7.49935 10.0001 7.49935C9.53984 7.49935 9.16675 7.12625 9.16675 6.66602C9.16675 6.20578 9.53984 5.83268 10.0001 5.83268C10.4603 5.83268 10.8334 6.20578 10.8334 6.66602Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationIcon;
