import { TableComponent } from '../Components/TableCom';

export default function Notifications() {
  return (
    <TableComponent
      extraQuery="&status=RIDE_PENDING"
      tableBaseUrl="v1/request/orders"
      tabelDescription="Notification"
      tableHeader={[
        'timestamp',
        'senderName',
        'sourceName',
        'recipientName',
        'destinationName',
        'rideStatus',
        'priceRange',
      ]}
      mappingData={{
        timestamp: 'Date',
        senderName: 'Sender',
        sourceName: 'Sender Address',
        recipientName: 'Receiver',
        destinationName: 'Receiver Address',
        rideStatus: 'Status',
        priceRange: 'Amount',
      }}
    />
  );
}
