import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { CompanyLetterLogo } from '../../../Components/CompanyLogo';
import { HoverDropDown } from '../../../Components/DropDown';
import { DownChevron } from '../../../Components/Icons/DownChevron';
import NotificationIcon from '../../../Components/Icons/NotificationIcon';
import SignOutIcon from '../../../Components/Icons/SignOutIcon';
import { StyledRouterLink } from '../../../Components/Links';
import AuthContext from '../../../Context/AuthContext';
import { truncateString } from '../../../utils';
import { HeaderNav } from '../nav/HeaderNav';

export const Header: React.FC<IHeader> = ({ navState }) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    authContext?.logout();
    navigate('account/signin');
  };

  return (
    <StyledHeader>
      <IconSection>
        <CompanyLogoWrapper>
          <CompanyLetterLogo />
        </CompanyLogoWrapper>
        <HeaderNav />
        <IconsStack>
          <StyledRouterLink to="/notifications">
            <NotificationIcon />
          </StyledRouterLink>
          <ProfileInfoArea>
            <HoverDropDown
              label={
                <DropDownLabel>
                  {truncateString(
                    authContext?.authState.user?.businessName,
                    18
                  ) || 'Damilola Banks'}
                  <DownChevron />
                </DropDownLabel>
              }
            >
              <DropDownContent onClick={handleLogout}>
                <SignOutIcon />
                Logout
              </DropDownContent>
            </HoverDropDown>
          </ProfileInfoArea>
        </IconsStack>
      </IconSection>
    </StyledHeader>
  );
};

const CompanyLogoWrapper = styled.div`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const IconsStack = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
`;

const ProfileInfoArea = styled.div`
  display: flex;
  /* border: 2px solid red; */
  align-items: center;
  gap: 0.5rem;
  color: black;
`;

const DropDownLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DropDownContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  width: fit-content;
  color: #867c79;
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  z-index: 10;
  position: sticky;
  top: 0;
  /* background-color: ${({ theme }) => theme.palette.primaryBgColor}; */
  background-color: white;
  & > * {
    padding: 0 2.5rem;
    height: 5rem;
    /* border-bottom: 1px solid ${({ theme }) =>
      theme.palette.primaryBorderColor}; */
  }

  @media screen and (max-width: 540px) {
    & > * {
      padding: 0 1rem 0 3rem;
    }
  }
`;

const IconSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

interface IHeader {
  navState: boolean;
}
