import { useField } from 'formik';
import { useState } from 'react';

import styled from '@emotion/styled';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  FieldErrorInfo,
  IInputField,
  Input,
  InputContainer,
  InputLabel,
  InputWrapper,
} from './FormField';

interface IHideableField extends IInputField {
  apiKey: string;
}

export const HideableandCopyableTextFormField: React.FC<IHideableField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  apiKey,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [showKey, setShowKey] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const handleToggle = () => {
    setShowKey(!showKey);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);

    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 500);
  };

  return (
    <>
      <InputWrapper className={className}>
        <InputLabelWrappper>
          <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
          {showKey ? (
            <VisibilityIcon
              onClick={handleToggle}
              sx={{ zIndex: 5, color: '#C2BEBC' }}
            />
          ) : (
            <VisibilityOffIcon
              onClick={handleToggle}
              sx={{ zIndex: 5, color: '#C2BEBC' }}
            />
          )}
        </InputLabelWrappper>

        <InputContainer>
          <Input
            {...field}
            {...props}
            type={showKey ? 'text' : 'password'}
            value={apiKey}
            readOnly
            rpiSrc
          />
          <CopyIconsArea>
            {showCopyMessage && <StyledCopyMessage>Copied</StyledCopyMessage>}
            <ContentCopyIcon
              onClick={handleCopy}
              sx={{ zIndex: 5, color: '#C2BEBC' }}
            />
          </CopyIconsArea>
        </InputContainer>
        {meta.touched && meta.error ? (
          <FieldErrorInfo>{meta.error}</FieldErrorInfo>
        ) : null}
      </InputWrapper>
    </>
  );
};

const CopyIconsArea = styled.div`
  position: relative;
  /* height: 1.5rem; */
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  /* gap: 0.5rem; */
  margin-right: 1rem;
  margin-left: auto;
  margin-top: 0.75rem;
  /* border: 2px solid red; */
`;

const InputLabelWrappper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.2rem;
`;

const StyledCopyMessage = styled.div`
  position: absolute;
  right: -0.2rem;
  top: -2em;
  font-weight: bold;
  font-size: 0.8em;
  color: ${({ theme }) => theme.palette.brandPrimary};
  background-color: white;
`;
