import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// import { AuthErrorBox } from '../../components/AuthErrorBox';
import { FormSubmitButton } from '../../Components/Button';
import { FormComponent } from '../../Components/FormCom/FormComponent';
import { TextInputField } from '../../Components/FormCom/FormField';
import { HideableTextFormField } from '../../Components/FormCom/HideableTextFormField';
import {
  initialValues,
  SignUpDataType,
  SignUpSchema,
} from '../../Models/auth/SignUp';
import { publicFetch } from '../../utils';

export const SignUpArea = () => {
  const navigate = useNavigate();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: SignUpDataType) => {
    try {
      setLoadingSpinner(true);
      const resData = await publicFetch.post('auth/business/register', {
        password: values.password,
        email: values.email,
        phoneNo: values.phone,
        businessName: values.businessName,
      });
      const userSignUpData = resData.data.data;
      setLoadingSpinner(false);
      navigate('/account/verify-otp', {
        replace: true,
        state: { data: userSignUpData },
      });
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <FormComponent
      initialValues={initialValues}
      schema={SignUpSchema}
      onSubmit={onSubmit}
    >
      <TextInputField
        label="Business Name"
        name="businessName"
        type="text"
        placeholder="Business Name"
        className={'spanTwo'}
      />
      <TextInputField
        label="Email"
        name="email"
        type="email"
        placeholder="Email"
      />
      <TextInputField
        label="Phone Number"
        name="phone"
        type="text"
        placeholder="0811 1111 111"
      />
      <HideableTextFormField
        label="Password"
        name="password"
        type="password"
        placeholder="Password"
      />

      <HideableTextFormField
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        placeholder="Confirm Password"
      />

      <FormSubmitButton
        className="spanTwo"
        type="submit"
        showIcon={loadingSpinner}
      >
        Create Account
      </FormSubmitButton>
    </FormComponent>
  );
};
