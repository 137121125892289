import { AuthLayout } from '../../Components/AuthLayout';
import Page from '../../Components/Page';

export default function ForgotPasswordEmailSent() {
  return (
    <Page title="Darum | Reset Password">
      <AuthLayout
        headerImgSrc="/static/svg/checkmail.svg"
        label="Already have an account?"
        linkTitle="Log In"
        linkPath="/account/signin"
        formHeaderTitle="Check your mail"
        formHeaderDescription="We've sent instructions to your email to reset your password. Please click on the link in the email to verify your email."
      ></AuthLayout>
    </Page>
  );
}
