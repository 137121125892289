import axios from 'axios';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configSetting } from '../../config';

type SubmittableOrdersStackPayload = PayloadAction<ISubmittableOrdersStack>;

interface OrderStack {
  pickupLocation: string;
  deliveryLocation: string;
  estimatedCost: string;
  receiverFullName: string;
  receiverPhoneNo: string;
  packageType: string;
  assignRider: string;
  deliverDate: string;
  deliverTime: string;
}

interface ISubmittableOrdersStack {
  userId: any;
  source: {
    placeId: any;
    primaryText: any;
    secondaryText: any;
    lat: Number;
    lng: Number;
  };
  destination: {
    placeId: any;
    primaryText: any;
    secondaryText: any;
    lat: Number;
    lng: Number;
  };
  sender: {
    category: any;
    quantity: any;
    fullName: any;
    phoneNo: any;
    deliveryNote: any;
  };
  recipient: {
    category: any;
    quantity: any;
    fullName: any;
    phoneNo: any;
    deliveryNote: any;
  };
}

interface IOrderInitailState {
  orderSuccessfulSubmitState: boolean;
  orderStatus: string;
  orderError?: string;
  totalEstimatedPrice: number;
  ordersCreated: number;
  // This is used to recovered the save order data at a given index in ordersStack
  ordersDataMark: number;
  ordersEditMode: boolean;
  ordersStack: OrderStack[];
  submittableOrdersStack: ISubmittableOrdersStack[];
}

const initialState: IOrderInitailState = {
  orderSuccessfulSubmitState: false,
  orderStatus: 'idle',
  orderError: '',
  totalEstimatedPrice: 0,
  ordersCreated: 0,
  ordersDataMark: 0,
  ordersEditMode: false,
  ordersStack: [],
  submittableOrdersStack: [],
};

export const placeOrders = createAsyncThunk(
  'orders/placeOrders',
  async (orders: ISubmittableOrdersStack[]) => {
    try {
      const { data } = await axios.post(
        `${configSetting.apiUrl}v1/request`,
        { requests: orders },
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    addToOrdersStack: (state, action) => {
      state.ordersStack.push(action.payload);
      state.ordersCreated += 1;
    },

    addToSubmittableOrdersStack: {
      reducer(state, action: SubmittableOrdersStackPayload) {
        state.submittableOrdersStack.push(action.payload);
      },
      prepare(values, authContext, orderContext) {
        return {
          payload: {
            userId: authContext?.authState.user.userId,
            source: {
              placeId: orderContext?.state.source.place_id || '',
              primaryText: orderContext?.state.source.primaryText,
              secondaryText: orderContext?.state.source.secondaryText,
              lat: orderContext?.state.source.lat as Number,
              lng: orderContext?.state.source.lng as Number,
            },
            destination: {
              placeId: orderContext?.state.destination.place_id || '',
              primaryText: orderContext?.state.destination.primaryText,
              secondaryText: orderContext?.state.destination.secondaryText,
              lat: orderContext?.state.destination.lat as Number,
              lng: orderContext?.state.destination.lng as Number,
            },
            estimatedRideObject: {
              destinationTime: values?.deliverTime,
              // TODO:: ask the backend for this
              pickUpTime: '12:56 PM',
              endPrice: values?.estimatedCost,
              startPrice: values?.estimatedCost,
            },
            sender: {
              category: 'Others',
              quantity: 1,
              fullName: authContext?.authState.user.businessName,
              phoneNo: authContext?.authState.user.phoneNo,
              deliveryNote: '',
            },
            recipient: {
              category: 'Others',
              quantity: 1,
              fullName: values?.receiverFullName,
              phoneNo: values?.receiverPhoneNo,
              deliveryNote: '',
            },
          },
        };
      },
    },

    updateOrdersStack: (state, action) => {
      const { index, data } = action.payload;
      state.ordersStack.splice(index, 1, data);
    },

    resetOrdersStack: (state) => {
      state.ordersStack = [];
      state.submittableOrdersStack = [];
      state.totalEstimatedPrice = 0;
      state.ordersCreated = 0;
      state.ordersDataMark = 0;
      state.ordersEditMode = false;
    },

    deleteOrderFromStack: (state, action) => {
      state.ordersStack.splice(action.payload, 1);
      state.submittableOrdersStack.splice(action.payload, 1);
      state.ordersCreated -= 1;
    },

    getOrderPrice: (state) => {
      let totalPrice = 0;

      state.ordersStack.forEach((item) => {
        totalPrice += +item.estimatedCost;
      });

      state.totalEstimatedPrice = totalPrice;
    },

    setOrdersDataMark: (state, action) => {
      state.ordersDataMark = action.payload;
    },

    setOrdersEditMode: (state, flag) => {
      state.ordersEditMode = flag.payload;
    },

    setOrderSuccessfulSubmitState: (state) => {
      state.orderSuccessfulSubmitState = true;
    },

    resetOrderSuccessfulSubmitState: (state) => {
      state.orderSuccessfulSubmitState = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(placeOrders.pending, (state, action) => {
      state.orderStatus = 'loading';
    });
    builder.addCase(placeOrders.fulfilled, (state, action) => {
      state.orderStatus = 'succeeded';
    });
    builder.addCase(placeOrders.rejected, (state, action) => {
      state.orderStatus = 'failed';
      state.orderError = action.error.message;
    });
  },
});

export const {
  deleteOrderFromStack,
  addToOrdersStack,
  getOrderPrice,
  setOrdersDataMark,
  setOrdersEditMode,
  updateOrdersStack,
  resetOrdersStack,
  setOrderSuccessfulSubmitState,
  resetOrderSuccessfulSubmitState,
  addToSubmittableOrdersStack,
} = ordersSlice.actions;
export const selectAllOrders = (state: any) => state.posts;
export default ordersSlice.reducer;
