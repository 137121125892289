import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { resetOrdersStack } from '../../redux/slices/ordersSlice';
import { useAppDisPatch } from '../../redux/store';

export const SimpleLayout = () => {
  const dispatch = useAppDisPatch();

  useEffect(() => {
    dispatch(resetOrdersStack());
  }, [dispatch]);

  return (
    <main>
      <Outlet />
    </main>
  );
};
