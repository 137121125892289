import React, { useContext, useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { BareButton, FormSubmitButton } from '../../../Components/Button';
import DefaultAddressSelectField from '../../../Components/FormCom/DefaultAddressSelectField';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import {
  SelectField,
  TextInputField,
} from '../../../Components/FormCom/FormField';
import { GooglePlacesInputField } from '../../../Components/FormCom/GooglePlacesInputField';
import { SectionContainer } from '../../../Components/SectionContainer';
import { ToggleButton } from '../../../Components/ToggleButton';
import AuthContext from '../../../Context/AuthContext';
import OrdersContext from '../../../Context/OrdersContext';
import {
  OrdersDataType,
  ordersInitialValues,
  OrdersSchema,
} from '../../../Models/orders/Orders';
import {
  addToOrdersStack,
  addToSubmittableOrdersStack,
  setOrdersEditMode,
  updateOrdersStack,
} from '../../../redux/slices/ordersSlice';
import { useAppDisPatch, useAppSelector } from '../../../redux/store';

interface IOrderFormSection {
  setShowBookADispatchPage: (...args: any) => void;
}

export const OrderFormSection: React.FC<IOrderFormSection> = ({
  setShowBookADispatchPage,
}) => {
  const { defaultAddress, otherAddresses } = useAppSelector(
    (state) => state.address
  );
  const [isDefaultAddress, setIsDefaultAddress] = useState(false);
  const orderContext = useContext(OrdersContext);
  const authContext = useContext(AuthContext);

  const dispatch = useAppDisPatch();
  const { ordersCreated, ordersEditMode, ordersDataMark, ordersStack } =
    useAppSelector((state) => state.orders);

  const [createAnotherOrder, setCreateAnotherOrder] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  let initialOrderData = ordersInitialValues;

  if (ordersEditMode) {
    initialOrderData = ordersStack[ordersDataMark];
  }

  useEffect(() => {
    if (ordersEditMode) {
      orderContext?.setDeliveryEstimate(`${initialOrderData.estimatedCost}`);
    }
  });

  const handleSubmit = (values: OrdersDataType, actions: any) => {
    values.estimatedCost = orderContext?.deliveryEstimate;
    values.deliveryLocation = orderContext?.state.destination
      .formatted_address as string;
    values.pickupLocation = orderContext?.state.source
      .formatted_address as string;

    if (ordersEditMode) {
      dispatch(updateOrdersStack({ index: ordersDataMark, data: values }));
    } else {
      dispatch(addToOrdersStack(values));
      dispatch(addToSubmittableOrdersStack(values, authContext, orderContext));
    }
    dispatch(setOrdersEditMode(false));
    if (!cancelOrder && !createAnotherOrder) {
      setShowBookADispatchPage(false);
    }
    if (createAnotherOrder) {
      actions.resetForm({ values: ordersInitialValues });
      orderContext?.setDeliveryEstimate(' - -');
    }
  };

  const estimatedDeliveryPrice =
    orderContext?.deliveryEstimate !== ' - -'
      ? Number(orderContext?.deliveryEstimate)?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN',
        })
      : orderContext?.deliveryEstimate;

  return (
    <FormSectionContainer>
      <FormComponent
        initialValues={initialOrderData}
        schema={OrdersSchema}
        onSubmit={handleSubmit}
        className="customFormLayout"
      >
        <SectionContainer
          title="Where's it going?"
          className={'spanTwo'}
          customController={
            <SetDefaultAddressContainer>
              Select from default locations{' '}
              <ToggleButton
                controller={() => setIsDefaultAddress(!isDefaultAddress)}
              />
            </SetDefaultAddressContainer>
          }
        >
          {isDefaultAddress ? (
            <>
              <DefaultAddressSelectField
                label="Pickup Location"
                name="pickupLocation"
                className={'spanTwo'}
              >
                <option
                  disabled
                  value=""
                  className="select-with-disabled-selected-option"
                >
                  {'Select a location'}
                </option>
                {defaultAddress.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.locationName}
                  </option>
                ))}

                {otherAddresses.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.locationName}
                  </option>
                ))}
              </DefaultAddressSelectField>
              <GooglePlacesInputField
                label="Drop-off Location"
                name="deliveryLocation"
                type="text"
                placeholder="Drop-off Location"
                className={'spanTwo'}
              />
            </>
          ) : (
            <>
              <GooglePlacesInputField
                label="Pickup Location"
                name="pickupLocation"
                type="text"
                placeholder="Pickup Location"
                className={'spanTwo'}
              />
              <GooglePlacesInputField
                label="Drop-off Location"
                name="deliveryLocation"
                type="text"
                placeholder="Drop-off Location"
                className={'spanTwo'}
              />
            </>
          )}
        </SectionContainer>
        <SectionContainer title="Receiver's details" className={'spanTwo'}>
          <BadgeArea className={'spanTwo'}>
            <LeftBadge>
              {ordersEditMode
                ? `Dropoff ${ordersDataMark + 1} of ${ordersStack.length}`
                : `Dropoff ${ordersCreated + 1} of 5`}
            </LeftBadge>
            <RightBadge>
              Estimated Cost:{' '}
              <BalanceSpan>{estimatedDeliveryPrice}</BalanceSpan>
            </RightBadge>
          </BadgeArea>
          <TextInputField
            label="Receiver’s Full Name"
            name="receiverFullName"
            type="text"
            placeholder="Receiver’s Full Name"
          />
          <TextInputField
            label="Receiver’s Phone No"
            name="receiverPhoneNo"
            type="text"
            placeholder="0811 1111 111"
          />
          <SelectField label="Package Type" name="packageType">
            <option
              disabled
              value=""
              className="select-with-disabled-selected-option"
            >
              {'Select Package Type'}
            </option>
            <option>Stationery</option>
            <option>Clothing</option>
            <option>Food</option>
            <option>Gadgets</option>
            <option>Others</option>
          </SelectField>
          <div></div>
          {/* <TextInputField
            label="Assign Rider"
            name="assignRider"
            type="text"
            placeholder="Assign Rider"
          /> */}
          {/* <TextInputField
            label="Delivery Date"
            name="deliverDate"
            type="date"
            placeholder="Delivery Date"
          /> */}
          {/* <TextInputField
            label="Delivery Time"
            name="deliverTime"
            type="time"
            placeholder="Delivery Time"
          /> */}
          {ordersCreated === 4 || ordersEditMode ? (
            ''
          ) : (
            <BareButton
              type="submit"
              value="addNewOrder"
              disabled={
                orderContext?.deliveryEstimate === ' - -' ? true : false
              }
              onClick={() => {
                setCancelOrder(false);
                setCreateAnotherOrder(true);
              }}
            >
              + Add another location
            </BareButton>
          )}

          <ButtonArea className="spanTwo">
            {ordersStack.length < 1 ? (
              ''
            ) : (
              <FormSubmitButton
                className="secoundary right-col-1"
                type="button"
                onClick={() => {
                  setCancelOrder(true);
                  setCreateAnotherOrder(false);
                  dispatch(setOrdersEditMode(false));
                  ordersStack.length > 0 && setShowBookADispatchPage(false);
                }}
              >
                Cancel
              </FormSubmitButton>
            )}
            <FormSubmitButton
              className={
                ordersStack.length < 1 ? 'spanTwo margin' : 'left-col-2'
              }
              type="submit"
              disabled={
                orderContext?.deliveryEstimate === ' - -' ? true : false
              }
              onClick={() => {
                setCancelOrder(false);
                setCreateAnotherOrder(false);
              }}
            >
              {ordersEditMode ? 'Save Changes' : 'Book Dispatch'}
            </FormSubmitButton>
          </ButtonArea>
        </SectionContainer>
      </FormComponent>
    </FormSectionContainer>
  );
};

const SetDefaultAddressContainer = styled.div`
  /* border: 2px solid rebeccapurple; */
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 4rem;
  /* border: 2px solid red; */
`;

const BalanceSpan = styled.span`
  font-size: 1.125rem;
  font-weight: 700;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const BadgeArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;

  @media screen and (max-width: 480px) {
    font-size: 0.675rem;
  }
`;
const LeftBadge = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.greyTertiary};
`;
const RightBadge = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.palette.brandBackdrop};
`;

const FormSectionContainer = styled.div`
  /* border: 2px solid blue;

  @media screen and (max-width: 480px) {
    width: 100%;
  } */
`;
