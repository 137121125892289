import React, { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

import AuthContext from '../../../Context/AuthContext';

export const HomeHeader = () => {
  const authContext = useContext(AuthContext);
  return (
    <HeaderContainer>
      <div>
        Welcome{' '}
        {authContext?.authState.user?.businessName || 'Darum for business'}
      </div>
      <StyledRouterLink to="/orders">
        <PlainButton>Book Dispatch</PlainButton>
      </StyledRouterLink>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  /* border: 2px solid red; */

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
`;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: None;
`;

const PlainButton = styled.button`
  padding: 1rem 2rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.brandPrimary};
  color: white;
  height: 48px;
  font-weight: 800;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
