import { Accordion, AccordionCell } from '../../../Components/Accordion';
import { StyledRouterLink } from '../../../Components/Links';
import { SegmentContainer } from './SegmentContainer';

export const Faq = () => {
  return (
    <SegmentContainer title="FAQ">
      <Accordion>
        <AccordionCell title="Help with a trip">
          To get more info on your current request, click the notification bell
          icon at the top right corner of the page or by going through this{' '}
          <StyledRouterLink to="/notifications">link</StyledRouterLink>.
        </AccordionCell>

        <AccordionCell title="Help with a previous order">
          To confirm your completed trips, click on the{' '}
          <StyledRouterLink to="/orders">orders tab</StyledRouterLink>.
        </AccordionCell>

        <AccordionCell title="Help with a payment">
          To make a payment, click on the{' '}
          <StyledRouterLink to="/payments">payment tab</StyledRouterLink> and
          input the amount to be paid in the text box labeled amount, this
          redirects you to a paystack checkout page.
        </AccordionCell>
      </Accordion>
    </SegmentContainer>
  );
};
