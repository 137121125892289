import * as Yup from 'yup';

export const SignInSchema = Yup.object().shape({
  // email: Yup.string().email().required('Email is required'),
  username: Yup.string().email().required('Username is required'),
  password: Yup.string().min(8).required('Please provide a your password'),
});

export type SignInDataType = Yup.InferType<typeof SignInSchema>;

type InitialValues = {
  // email: string;
  username: string;
  password: string;
};

export const signinInitialValues: InitialValues = {
  // email: '',
  username: '',
  password: '',
};

export interface IUserData {
  userId: string;
  businessName: string;
  category?: string | null;
  email: string;
  phoneNo?: string;
  gender?: string;
  deviceOS?: string;
  paymentType?: string;
  createdAt: string;
  verified: boolean;
  type: string;
  referralCode?: string | null;
}

export interface IsignInData {
  user?: IUserData;
  token?: string | null;
}
