import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

export const InternalLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.brandPrimary};
  font-weight: 700;
`;

// old code

export const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

export const StyledFormFooterLink = styled(RouterLink)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 0.88rem;
  text-align: left;
  color: ${({ theme }) => theme.palette.primaryTextColor};
`;
