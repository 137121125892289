import styled from '@emotion/styled';

import Page from '../Components/Page';

export default function Page404() {
  return (
    <Page title="Darum | 404">
      <Styled404>
        <img alt="darun" src="/static/img/darum-logo.png" />
        Page Not Found
      </Styled404>
    </Page>
  );
}

const Styled404 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 5em;
  margin-top: -1em;
  color: ${({ theme }) => theme.palette.tertiaryBgColor};
`;
