import React, { useContext } from 'react';

import styled from '@emotion/styled';

import { Modal } from '../../../Components/Modal';
import { ModalContext } from '../../../Context/ModalContext';
// import {
//   setOrdersDataMark,
//   setOrdersEditMode,
// } from '../../../redux/slices/ordersSlice';
// import { useAppDisPatch } from '../../../redux/store';
import CancelOrderModalComponent from './CancelOrderModalComponent';

interface IOrderSummaryCard {
  width?: string;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;

  //
  pickupLocation: string;
  deliveryLocation: string;
  estimatedCost: string;
  receiverFullName: string;
  index: number;
  ordersCreated: number;
  setShowBookADispatchPage: (...args: any) => void;
}
export const OrderSummaryCard: React.FC<IOrderSummaryCard> = ({
  width,
  title,
  pickupLocation,
  deliveryLocation,
  estimatedCost,
  receiverFullName,
  index,
  ordersCreated,
  // setShowBookADispatchPage,
}) => {
  const modalContext = useContext(ModalContext);
  // const dispatch = useAppDisPatch();

  // const showDelteOrderModal = () => {
  //   alert('btton dey click o');
  //   modalContext?.displayModal(true);
  // };

  return (
    <>
      <BadgeArea className={'spanTwo'}>
        <LeftBadge>{`Dropoff ${index + 1} of ${ordersCreated}`}</LeftBadge>
        <IconArea>
          {/* <Img
            src="/static/svg/edit.svg"
            onClick={() => {
              dispatch(setOrdersEditMode(true));
              dispatch(setOrdersDataMark(index));
              setShowBookADispatchPage(true);
            }}
          /> */}
          <Img
            src="/static/svg/bin.svg"
            // onClick={() => dispatch(deleteOrderFromStack(index))}
            onClick={() => {
              modalContext?.displayModal(true);
            }}
          />
          <Modal>
            <CancelOrderModalComponent
              index={index}
              ordersCreated={ordersCreated}
            />
          </Modal>
        </IconArea>
      </BadgeArea>
      <AddressCard>
        <AddressCardHeader>
          <SenderNameTag>{receiverFullName}</SenderNameTag>
          <AmountTag>
            {Number(estimatedCost)?.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN',
            })}
          </AmountTag>
        </AddressCardHeader>
        <Ul>
          <li>
            <LiLabel>Pickup</LiLabel>
            <LiAddress>{pickupLocation}</LiAddress>
          </li>
          <li>
            <LiLabel>Drop-off</LiLabel>
            <LiAddress>{deliveryLocation}</LiAddress>
          </li>
        </Ul>
      </AddressCard>
    </>
  );
};

const Ul = styled.ul`
  list-style: none;
  padding: 0px;
  margin-left: -1rem;

  & li:first-of-type {
    margin-bottom: 1rem;
  }

  & li {
    position: relative;
    margin-left: 20px;
    padding-left: 30px;
  }

  & li:nth-of-type(1)::before {
    content: url('/static/svg/startLocation.svg');
    position: absolute;
    left: 0;
    top: calc(0% + 4px);
  }

  & li:nth-of-type(2)::before {
    content: url('/static/svg/endLocation.svg');
    position: absolute;
    left: -1px;
    top: calc(0% + 4px);
  }

  & li:nth-of-type(1)::after {
    content: '';
    position: absolute;
    top: 22px;
    left: 8.5px;
    width: 1px;
    height: 100%;
    border-left: 1px dashed ${({ theme }) => theme.palette.tertiaryText};
  }
`;

const LiLabel = styled.div`
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const LiAddress = styled.div`
  font-size: 0.875rem;
`;

const AddressCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountTag = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const SenderNameTag = styled.div`
  color: ${({ theme }) => theme.palette.primaryText};
`;

const AddressCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
`;

const Img = styled.img`
  cursor: pointer;
`;

const IconArea = styled.div`
  display: flex;
  gap: 1rem;
`;

const BadgeArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
`;
const LeftBadge = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.greyTertiary};
`;
