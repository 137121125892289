import React from 'react';

export const SettingsIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00037 11.75C10.243 11.75 11.2504 10.7426 11.2504 9.5C11.2504 8.25736 10.243 7.25 9.00037 7.25C7.75773 7.25 6.75037 8.25736 6.75037 9.5C6.75037 10.7426 7.75773 11.75 9.00037 11.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96718 15.0283L7.40551 16.0142C7.53582 16.3076 7.74847 16.557 8.01768 16.732C8.28689 16.9069 8.6011 17.0001 8.92218 17C9.24326 17.0001 9.55747 16.9069 9.82668 16.732C10.0959 16.557 10.3085 16.3076 10.4388 16.0142L10.8772 15.0283C11.0332 14.6785 11.2957 14.3869 11.6272 14.195C11.9608 14.0026 12.3467 13.9206 12.7297 13.9608L13.8022 14.075C14.1214 14.1088 14.4436 14.0492 14.7297 13.9035C15.0158 13.7578 15.2534 13.5322 15.4138 13.2542C15.5745 12.9763 15.651 12.6577 15.6341 12.3372C15.6173 12.0166 15.5078 11.7079 15.3188 11.4483L14.6838 10.5758C14.4577 10.2628 14.3369 9.88611 14.3388 9.5C14.3388 9.11494 14.4607 8.73976 14.6872 8.42833L15.3222 7.55583C15.5111 7.29632 15.6206 6.98755 15.6375 6.66701C15.6543 6.34646 15.5778 6.02791 15.4172 5.75C15.2568 5.47193 15.0191 5.24637 14.733 5.10067C14.447 4.95497 14.1248 4.8954 13.8055 4.92917L12.733 5.04333C12.35 5.08356 11.9641 5.00159 11.6305 4.80917C11.2984 4.61619 11.0358 4.32302 10.8805 3.97167L10.4388 2.98583C10.3085 2.69238 10.0959 2.44304 9.82668 2.26805C9.55747 2.09306 9.24326 1.99995 8.92218 2C8.6011 1.99995 8.28689 2.09306 8.01768 2.26805C7.74847 2.44304 7.53582 2.69238 7.40551 2.98583L6.96718 3.97167C6.81188 4.32302 6.54933 4.61619 6.21718 4.80917C5.88359 5.00159 5.49768 5.08356 5.11468 5.04333L4.03885 4.92917C3.71959 4.8954 3.3974 4.95497 3.11133 5.10067C2.82526 5.24637 2.58761 5.47193 2.42718 5.75C2.26655 6.02791 2.19002 6.34646 2.20688 6.66701C2.22374 6.98755 2.33327 7.29632 2.52218 7.55583L3.15718 8.42833C3.38364 8.73976 3.50558 9.11494 3.50551 9.5C3.50558 9.88506 3.38364 10.2602 3.15718 10.5717L2.52218 11.4442C2.33327 11.7037 2.22374 12.0124 2.20688 12.333C2.19002 12.6535 2.26655 12.9721 2.42718 13.25C2.58777 13.5279 2.82545 13.7534 3.11148 13.899C3.39751 14.0447 3.71962 14.1044 4.03885 14.0708L5.11135 13.9567C5.49435 13.9164 5.88026 13.9984 6.21385 14.1908C6.54724 14.3833 6.811 14.6765 6.96718 15.0283Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
