import React from 'react';

export const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12.25H11M7.26327 1.57306L2.17654 5.5294C1.83652 5.79387 1.6665 5.9261 1.54402 6.0917C1.43552 6.23839 1.3547 6.40365 1.30552 6.57935C1.25 6.7777 1.25 6.99308 1.25 7.42385V12.85C1.25 13.6901 1.25 14.1102 1.41349 14.431C1.5573 14.7133 1.78677 14.9427 2.06901 15.0866C2.38988 15.25 2.80992 15.25 3.65 15.25H12.35C13.1901 15.25 13.6101 15.25 13.931 15.0866C14.2132 14.9427 14.4427 14.7133 14.5865 14.431C14.75 14.1102 14.75 13.6901 14.75 12.85V7.42385C14.75 6.99308 14.75 6.7777 14.6945 6.57935C14.6453 6.40365 14.5645 6.23839 14.456 6.0917C14.3335 5.9261 14.1635 5.79387 13.8235 5.52941L8.73673 1.57306C8.47324 1.36812 8.34149 1.26565 8.19601 1.22626C8.06765 1.19151 7.93235 1.19151 7.80399 1.22626C7.65851 1.26565 7.52677 1.36812 7.26327 1.57306Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
