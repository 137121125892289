import Page from '../Components/Page';
import { PageTitle } from '../Components/PageTitle';
import {
  SegmentedView,
  SegmentedViewController,
  SegmentedViewData,
} from '../Components/SegmentedView';
import { Api } from '../Sections/dashboard/settings/Api';
import { Profile } from '../Sections/dashboard/settings/Profile';
import { Security } from '../Sections/dashboard/settings/Security';
import { Webhook } from '../Sections/dashboard/settings/Webhook';

const segmentedViewControllerTitle = [
  { title: 'Profile' },
  { title: 'Security' },
  { title: 'Api' },
  { title: 'Webhook' },
];

export default function Settings() {
  return (
    <Page title="Darum | Settings">
      <PageTitle title="Settings">
        <SegmentedView>
          <SegmentedViewController
            segmentedViewControllerTitle={segmentedViewControllerTitle}
          />
          <SegmentedViewData>
            <div>
              <Profile />
            </div>
            <div>
              <Security />
            </div>
            <div>
              <Api />
            </div>
            <div>
              <Webhook />
            </div>
          </SegmentedViewData>
        </SegmentedView>
      </PageTitle>
    </Page>
  );
}
