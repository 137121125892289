import { Toaster } from 'react-hot-toast';

import ScrollToTop from './Components/ScrollToTop';
import Router from './routes/routes';

function App() {
  return (
    <>
      <ScrollToTop />
      <Router />
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
