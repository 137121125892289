import { AuthLayout } from '../../Components/AuthLayout';
import Page from '../../Components/Page';
import { ResetPassword } from '../../Sections/auth/ResetPassword';

export default function ForgotPassword() {
  return (
    <Page title="Darum | Reset Password">
      <AuthLayout
        label="Already have an account?"
        linkTitle="Log In"
        linkPath="/account/signin"
        formHeaderTitle="Forgot Password"
        formHeaderDescription="A password reset link will be sent to the email associated with your account."
      >
        <ResetPassword />
      </AuthLayout>
    </Page>
  );
}
