import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { FormSubmitButton } from '../../Components/Button';
import { FormComponent } from '../../Components/FormCom/FormComponent';
import { TextInputField } from '../../Components/FormCom/FormField';
import {
  resetPasswordInitialValues,
  ResetPaswdDataType,
  ResetPaswdSchema,
} from '../../Models/auth/ResetPassword';
import { publicFetch } from '../../utils';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: ResetPaswdDataType) => {
    try {
      setLoadingSpinner(true);
      await publicFetch.post(
        `auth/business/password/forget?email=${values.email}`
      );
      setLoadingSpinner(false);
      navigate('/account/reset-password/verify');
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <FormComponent
      initialValues={resetPasswordInitialValues}
      schema={ResetPaswdSchema}
      onSubmit={onSubmit}
    >
      <TextInputField
        label="Email"
        name="email"
        type="email"
        placeholder="Email"
        className={'spanTwo margin'}
      />

      <FormSubmitButton
        className="spanTwo"
        type="submit"
        showIcon={loadingSpinner}
      >
        Reset
      </FormSubmitButton>
    </FormComponent>
  );
};
