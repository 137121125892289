import React, { useState } from 'react';
import toast from 'react-hot-toast';

import styled from '@emotion/styled';

import { HoverDropDown } from '../../../../Components/DropDown';
import EditLocationIcon from '../../../../Components/Icons/EditLocationIcon';
import ThreeDotIcon from '../../../../Components/Icons/ThreeDotIcon';
import { Modal } from '../../../../Components/Modal';
import { SpinningIcon } from '../../../../Components/SpinningIcon';
import { ModalProvider } from '../../../../Context/ModalContext';
import { OrdersProvider } from '../../../../Context/OrdersContext';
import {
  fetchAddress,
  setDefaultAddress,
} from '../../../../redux/slices/addressSlice';
import { useAppDisPatch } from '../../../../redux/store';
import { truncateString } from '../../../../utils';
import EditAddressMenuCell from './EditAddressMenuCell';
import { EditAddressModalContent } from './EditAddressModalContent';
import { IAddressPlace } from './interface';

export const AddressPlace: React.FC<Omit<IAddressPlace, 'categoryName'>> = ({
  defaultAddress,
  addressData,
}) => {
  const dispatch = useAppDisPatch();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const handleSetDefaultAddress = async (values: any) => {
    try {
      setLoadingSpinner(true);
      await dispatch(setDefaultAddress(values));
      dispatch(fetchAddress());

      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {addressData.map((data, index) => (
        <AddressArea key={index}>
          {defaultAddress ? (
            <img src="/static/svg/box.svg" alt="default address" />
          ) : (
            <img src="/static/svg/location.svg" alt="non default address" />
          )}
          <AddressSubArea>
            <div>
              <LocationDiscription>{data.locationName}</LocationDiscription>
            </div>
            <AddressInfo>
              <span>
                Name: <Span>{truncateString(data.profileName, 13)}</Span>
              </span>
              <GapSpan />
              <span>
                Phone Number:
                <Span>{truncateString(data.phoneNumber, 14)}</Span>
              </span>
              <GapSpan />
              <span>
                Address: <Span>{truncateString(data.primaryText, 14)}</Span>
              </span>
            </AddressInfo>
          </AddressSubArea>

          <ThreeDotMenu>
            <HoverDropDown label={<ThreeDotIcon />}>
              <DropDownContent>
                <ModalProvider>
                  <OrdersProvider>
                    <EditAddressMenuCell />
                    <Modal>
                      <EditAddressModalContent
                        addressId={data.id}
                        initialEditContent={{
                          locationName: data.locationName,
                          profileName: data.profileName,
                          address: `${data.primaryText} `,
                          phoneNumber: data.phoneNumber,
                        }}
                      />
                    </Modal>
                  </OrdersProvider>
                </ModalProvider>
                {!data.defaultAddress && (
                  <DropDownContentCell
                    onClick={() => {
                      handleSetDefaultAddress(data);
                    }}
                  >
                    <EditLocationIcon /> Set as default Address{' '}
                    {loadingSpinner && (
                      <SpinningIcon color={'#F46F3B'} size={20} />
                    )}
                  </DropDownContentCell>
                )}
              </DropDownContent>
            </HoverDropDown>
          </ThreeDotMenu>
        </AddressArea>
      ))}
    </>
  );
};

export const GapSpan = () => {
  return <HSpan></HSpan>;
};

const DropDownContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 16.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  /* z-index: 1000; */
  /* background-color: red; */
`;

const DropDownContentCell = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.brandBackdrop};
  }
`;

const AddressSubArea = styled.div`
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  width: 100%;

  @media (max-width: 480px) {
    gap: 0.63rem;
  }
`;

const HSpan = styled.span`
  /* background-color: ${({ theme }) => theme.palette.brandGrey}; */
  /* background-color: red; */
  /* width: 4px; */
  border-left: 1.4px solid ${({ theme }) => theme.palette.brandGrey};
  margin-left: 9px;
  margin-right: 6px;
`;

const Span = styled.span`
  color: ${({ theme }) => theme.palette.blackPrimary};
`;

const AddressInfo = styled.div`
  font-size: 0.875;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const LocationDiscription = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blackPrimary};
`;

const ThreeDotMenu = styled.div`
  padding-top: 8px;
`;

const AddressArea = styled.div`
  display: flex;
  gap: 1rem;
  margin-right: -0.5rem;
  /* border: 2px solid blue; */
  align-items: flex-start;
`;
