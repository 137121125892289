import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { configSetting } from '../../config';

interface IfundWalletInitailState {
  fundWalletStatus: string;
  fundWalletBalance: number;
  fundWalletError?: string | null;
}

const initialState: IfundWalletInitailState = {
  fundWalletStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  fundWalletBalance: 0,
  fundWalletError: null,
};

export const fundWallet = createAsyncThunk(
  'wallet/fundWallet',
  async (amount: number) => {
    try {
      const { data } = await axios.post(
        `${configSetting.apiUrl}v1/wallet?amount=${amount}`,
        {},
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      return error.message;
    }
  }
);

export const funsWalletSlice = createSlice({
  name: 'fundWallet',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fundWallet.pending, (state, action) => {
      state.fundWalletStatus = 'loading';
    });
    builder.addCase(fundWallet.fulfilled, (state, action) => {
      state.fundWalletStatus = 'succeeded';
    });
    builder.addCase(fundWallet.rejected, (state, action) => {
      state.fundWalletStatus = 'failed';
      state.fundWalletError = action.error.message;
    });
  },
});

// export const {} = walletSlice.actions;
export default funsWalletSlice.reducer;
