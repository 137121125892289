import React from 'react';

const BackArrowIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2462_23881"
        // style={"mask-type:alpha"}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2462_23881)">
        <path
          d="M9.33333 12.8334L3.5 7.00008L9.33333 1.16675L10.3687 2.20216L5.57083 7.00008L10.3687 11.798L9.33333 12.8334Z"
          fill="currentColor"
        />
      </g>
    </svg>

    // <svg
    //   width="8"
    //   height="12"
    //   viewBox="0 0 8 12"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M6.33333 11.8334L0.5 6.00008L6.33333 0.166748L7.36875 1.20216L2.57083 6.00008L7.36875 10.798L6.33333 11.8334Z"
    //     fill="currentColor"
    //   />
    // </svg>
  );
};

export default BackArrowIcon;
