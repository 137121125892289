import { useField } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';

import OrdersContext from '../../Context/OrdersContext';
import {
  FieldErrorInfo,
  IInputField,
  Input,
  InputContainer,
  InputLabel,
  InputWrapper,
} from './FormField';

export const GooglePlacesInputField: React.FC<IInputField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);
  const orderContext = useContext(OrdersContext);
  const autoCompleteRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [fullAddress, setFullAddress] = useState(field.value || '');

  useEffect(() => {
    const options = {
      componentRestrictions: { country: 'ng' },
      fields: ['place_id', 'formatted_address', 'geometry', 'name'],
      // types: ['address'],
    };

    setFullAddress(inputRef.current.value);
    if (inputRef.current !== null) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      autoCompleteRef.current.addListener('place_changed', async function () {
        const place = await autoCompleteRef.current.getPlace();
        setFullAddress(place?.formatted_address || '');

        const place_id = place?.place_id || '';
        const primaryText = place?.name || '';
        const lat = place?.geometry.location.lat() || '';
        const lng = place?.geometry.location.lng() || '';

        const split_formatted_address = place?.formatted_address.split(',');
        let secondaryText = '';

        if (split_formatted_address?.length) {
          secondaryText =
            split_formatted_address[split_formatted_address.length - 3]?.trim();
        }

        const coordinate_info = {
          place_id,
          primaryText,
          secondaryText,
          lat,
          lng,
          formatted_address: place?.formatted_address,
        };
        orderContext?.dispatch({ type: props.name, payload: coordinate_info });
      });
    }
    // eslint-disable-next-line
  }, [orderContext, props.id]);

  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <Input
          ref={inputRef}
          {...field}
          {...props}
          value={field.value === '' ? field.value : fullAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFullAddress(e.target.value);
            return field.onChange(e);
          }}
        />
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};
