import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

interface ICard {
  label: any;
  descriptionData?: string;
  focusData?: any;
  actionLabel?: any;
  iconSrc: string;
  to: string;
  boldLabel?: boolean;
}

export const Card: React.FC<ICard> = ({
  label,
  focusData,
  actionLabel,
  descriptionData,
  iconSrc,
  to,
  boldLabel,
}) => {
  return (
    <CardContainer>
      <LeftSection>
        <Label className={boldLabel ? 'boldLabel' : ''}>{label}</Label>
        <LeftSectionUpper>
          {!descriptionData && (
            <>
              <FocusContent>{focusData}</FocusContent>
            </>
          )}
          <DescriptionData>{descriptionData}</DescriptionData>
        </LeftSectionUpper>
        <CardActionBtn to={to}>{actionLabel}</CardActionBtn>
      </LeftSection>
      <RightSection>
        <Img src={iconSrc} />
      </RightSection>
    </CardContainer>
  );
};

const Img = styled.img`
  min-width: 4.1rem;
`;

const CardContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  /* align-items: center; */
  padding: 2rem 1.25rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const LeftSectionUpper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const DescriptionData = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const Label = styled.div`
  color: ${({ theme }) => theme.palette.primaryText};
  font-weight: 400;
  font-size: 0.875rem;

  &.boldLabel {
    font-weight: 700;
    font-size: 1rem;
  }
`;

const RightSection = styled.div`
  display: flex;
  /* align-self: flex-end; */
  /* margin-bottom: -1rem; */
`;

const FocusContent = styled.div`
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const CardActionBtn = styled(RouterLink)`
  text-decoration: none;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.tertiaryText};
`;
