import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../Components/Button';
import { CountDownTimer } from '../../Components/CountDownTimer';
import { FormComponent } from '../../Components/FormCom/FormComponent';
import { TextInputField } from '../../Components/FormCom/FormField';
import {
  VerifySignUpOtpDataType,
  verifySignUpOtpInitialValues,
  verifySignUpOtpSchema,
} from '../../Models/auth/VerifySignUpOtp';
import { publicFetch } from '../../utils';

export const VerifySignUpOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.data.userId;
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [showResendOtpButton, setShowResendOtpButton] = useState(false);

  const handleResentOtp = async () => {
    try {
      setLoadingSpinner(true);
      await publicFetch.post(`auth/otp/resend?userId=${userId}`);
      setLoadingSpinner(false);
      setShowResendOtpButton(false);
      toast.success('OTP Verification found.');
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.response);
      }
    }
  };

  const onSubmit = async (values: VerifySignUpOtpDataType) => {
    try {
      setLoadingSpinner(true);
      await publicFetch.post(
        `auth/otp/verify?userId=${userId}&code=${values.otp}`
      );

      setLoadingSpinner(false);
      navigate('/account/signin', { replace: true });
    } catch (error: any) {
      setLoadingSpinner(false);
      setShowResendOtpButton(true);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container>
      <FormComponent
        initialValues={verifySignUpOtpInitialValues}
        schema={verifySignUpOtpSchema}
        onSubmit={onSubmit}
      >
        <TextInputField
          label="Verification Code"
          name="otp"
          type="text"
          placeholder="193958"
          className={'spanTwo margin'}
        />

        {!showResendOtpButton && (
          <FormSubmitButton
            className="spanTwo"
            type="submit"
            showIcon={loadingSpinner}
          >
            Verify Account
          </FormSubmitButton>
        )}
      </FormComponent>
      {showResendOtpButton ? (
        <FormSubmitButton showIcon={loadingSpinner} onClick={handleResentOtp}>
          Resend OTP
        </FormSubmitButton>
      ) : (
        <ResetOtp className="spanTwo">
          Resend OTP in{' '}
          <CountDownTimer
            initialTime={300}
            setShowResendOtpButton={setShowResendOtpButton}
          />
        </ResetOtp>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResetOtp = styled.div`
  color: ${({ theme }) => theme.palette.tertiaryText};
  font-weight: 400;
  text-align: center;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.15rem;
`;
