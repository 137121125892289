import * as Yup from 'yup';

export const ApiSchema = Yup.object().shape({
  // apiKey: Yup.string()
  //   .min(8)
  //   .required('Please provide a your current password'),
});

export type ApiDataType = Yup.InferType<typeof ApiSchema>;

type InitialValues = {
  apiKey: string;
};

export const apiInitialValues: InitialValues = {
  apiKey: '',
};
