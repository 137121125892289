import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../../Components/Button';
import { Modal } from '../../../Components/Modal';
import { ModalProvider } from '../../../Context/ModalContext';
import {
  placeOrders,
  resetOrdersStack,
  setOrderSuccessfulSubmitState,
} from '../../../redux/slices/ordersSlice';
import { useAppDisPatch, useAppSelector } from '../../../redux/store';
import FundWalletModalFormContent from './FundWalletModalFormContent';
import FundWalletToBookDispatchButton from './FundWalletToBookDispatchButton';

interface IDispatchSummaryOrderSubmitButtons {
  setShowBookADispatchPage: (...args: any) => void;
}

const DispatchSummaryOrderSubmitButtons: React.FC<
  IDispatchSummaryOrderSubmitButtons
> = ({ setShowBookADispatchPage }) => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const navigate = useNavigate();
  const [showSuccessBtn, setShowSuccessBtn] = useState(false);
  const dispatch = useAppDisPatch();
  const {
    totalEstimatedPrice,
    submittableOrdersStack,
    ordersCreated,
    // orderStatus,
  } = useAppSelector((state) => state.orders);
  const { walletBalance } = useAppSelector((state) => state.wallet);

  const orderSubmitClause = walletBalance >= totalEstimatedPrice;

  const handleConfirmOrders = async () => {
    try {
      setLoadingSpinner(true);
      // !comback and look at this implementation for failure cases
      const res = await dispatch(placeOrders(submittableOrdersStack));
      if (res.payload === 'Request failed with status code 400') {
        toast.error(
          'You do not have enough money in your wallet to pay for this request'
        );
        throw new Error(
          'You do not have enough money in your wallet to pay for this request'
        );
      }
      dispatch(setOrderSuccessfulSubmitState());
      setShowSuccessBtn(true);
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        console.error(data);
        // setErrorResponse(data.message);
      } else {
        // setErrorResponse(error.message);
      }
    }
  };

  const SubmitCLauseBtn = () => {
    return (
      <>
        {orderSubmitClause ? (
          // <ButtonArea className="spanTwo">
          <ButtonArea className="spanTwo">
            {ordersCreated < 5 && (
              <FormSubmitButton
                className="secoundary right-col-1"
                type="button"
                onClick={() => {
                  // dispatch(resetOrdersStack());
                  setShowBookADispatchPage(true);
                }}
              >
                Cancel
              </FormSubmitButton>
            )}

            <FormSubmitButton
              className="left-col-2"
              onClick={handleConfirmOrders}
              showIcon={loadingSpinner}
            >
              Confirm Dispatch
            </FormSubmitButton>
          </ButtonArea>
        ) : (
          <ModalProvider>
            <FundWalletToBookDispatchButton />
            <Modal>
              <FundWalletModalFormContent />
            </Modal>
          </ModalProvider>
        )}
      </>
    );
  };

  const ShowSuccessBtn = () => (
    <>
      <ButtonArea className="spanTwo">
        <FormSubmitButton
          className="secoundary right-col-1"
          type="button"
          onClick={() => {
            dispatch(resetOrdersStack());
            setShowBookADispatchPage(true);
          }}
        >
          Book another Dispatch
        </FormSubmitButton>
        <FormSubmitButton
          className="left-col-2"
          onClick={() => {
            dispatch(resetOrdersStack());
            navigate('/');
          }}
        >
          Done
        </FormSubmitButton>
      </ButtonArea>
    </>
  );

  return <>{!showSuccessBtn ? <SubmitCLauseBtn /> : <ShowSuccessBtn />}</>;
};

export default DispatchSummaryOrderSubmitButtons;

// styles

const ButtonArea = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  /* border: 2px solid green; */
`;
