import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import FetchContext from './FetchContext';

const OrdersContext = createContext<IOrdersContext | null>(null);
const { Provider } = OrdersContext;

export enum OrderActionType {
  PICKUPLOCATION = 'pickupLocation',
  DELIVERYLOCATION = 'deliveryLocation',
  ADDRESS = 'address',
}

interface IPayload {
  place_id?: string;
  primaryText?: string;
  secondaryText?: string;
  lat?: string;
  lng?: string;
  formatted_address?: string;
}

interface IOrderDispatch {
  type: any;
  payload: IPayload;
}

interface IOrdersContext {
  state: IOrderState;
  dispatch: ({ type, payload }: IOrderDispatch) => any;
  deliveryEstimate: string;
  setDeliveryEstimate: (...args: any) => void;
}

export interface IOrderActionType {
  type: OrderActionType;
  payload: IPayload;
}

interface IOrderState {
  source: IPayload;
  destination: IPayload;
  address: IPayload;
}

// Reducers

const orderReducer = (state: IOrderState, action: IOrderActionType) => {
  switch (action.type) {
    case OrderActionType.PICKUPLOCATION:
      return { ...state, source: action.payload };

    case OrderActionType.DELIVERYLOCATION:
      return { ...state, destination: action.payload };

    case OrderActionType.ADDRESS:
      return { ...state, address: action.payload };

    default:
      return state;
  }
};

export const OrdersProvider: React.FC<any> = ({ children }) => {
  const fetchContext = useContext(FetchContext);
  const [deliveryEstimate, setDeliveryEstimate] = useState(' - -');
  const [state, dispatch] = useReducer(orderReducer, {
    source: {},
    destination: {},
    address: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      const sourceLat = state.source.lat;
      const sourceLng = state.source.lng;
      const destinationLat = state.destination.lat;
      const destinationLng = state.destination.lng;

      try {
        // ! important code below
        const priceEstimateData = await fetchContext?.authAxios.get(
          `v1/business/request/estimate?sourceLat=${sourceLat}&sourceLng=${sourceLng}&destinationLat=${destinationLat}&destinationLng=${destinationLng}`
        );
        // // Todo:: comeback and fix this
        // eslint-disable-next-line
        const { startPrice, endPrice } = priceEstimateData.data.data;
        // setDeliveryEstimate(`₦${startPrice} - ₦${endPrice}`);
        // setDeliveryEstimate('1000');
        setDeliveryEstimate(endPrice);
      } catch (error) {
        // Todo:: Come back to delete this next line
        // setDeliveryEstimate('20000');
        console.error(error);
      }
    };

    if (
      Object.keys(state.source).length > 0 &&
      Object.keys(state.destination).length
    ) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [state]);
  return (
    <Provider
      value={{ state, dispatch, deliveryEstimate, setDeliveryEstimate }}
    >
      {children}
    </Provider>
  );
};

export default OrdersContext;
