import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { configSetting } from '../../config';

interface IWalletInitailState {
  walletStatus: string;
  walletBalance: number;
  error?: string | null;
}

const initialState: IWalletInitailState = {
  walletStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  walletBalance: 0,
  error: null,
};

export const fetchBalance = createAsyncThunk(
  'wallet/fetchBalance',
  async () => {
    try {
      const { data } = await axios.get(`${configSetting.apiUrl}v1/wallet`, {
        headers: {
          Authorization: `Basic ${localStorage.getItem('token')}`,
        },
      });
      return data;
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchBalance.pending, (state, action) => {
      state.walletStatus = 'loading';
    });
    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.walletStatus = 'succeeded';
      state.walletBalance = action.payload.data;
      // state.walletBalance = 10000;
    });
    builder.addCase(fetchBalance.rejected, (state, action) => {
      state.walletStatus = 'failed';
      state.error = action.error.message;
    });
  },
});

// export const {} = walletSlice.actions;
export default walletSlice.reducer;
