import { useState } from 'react';

import Page from '../Components/Page';
import { PageTitle } from '../Components/PageTitle';
import { useAppSelector } from '../redux/store';
import { DispatchSummary } from '../Sections/dashboard/orders/DispatchSummary';
import { OrderSection } from '../Sections/dashboard/orders/OrderSection';

export default function Orders() {
  const [showBookADispatchPage, setShowBookADispatchPage] = useState(true);
  const { orderSuccessfulSubmitState, ordersCreated } = useAppSelector(
    (state) => state.orders
  );

  return (
    <Page title="Darum | Orders">
      {showBookADispatchPage && ordersCreated !== 5 ? (
        <PageTitle title="Book a dispatcher">
          <OrderSection setShowBookADispatchPage={setShowBookADispatchPage} />
        </PageTitle>
      ) : (
        <PageTitle
          title="Dispatch Summary"
          backbtn={
            !orderSuccessfulSubmitState && ordersCreated < 5 ? true : false
          }
          btnCallBack={setShowBookADispatchPage}
        >
          <DispatchSummary
            setShowBookADispatchPage={setShowBookADispatchPage}
          />
        </PageTitle>
      )}
    </Page>
  );
}
