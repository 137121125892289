import styled from '@emotion/styled';

import { Modal } from '../Components/Modal';
import Page from '../Components/Page';
import { TableComponent } from '../Components/TableCom';
import { ModalProvider } from '../Context/ModalContext';
import { OrdersProvider } from '../Context/OrdersContext';
import { ActionCards } from '../Sections/dashboard/home/ActionCards';
import { HomeHeader } from '../Sections/dashboard/home/HomeHeader';
import { HomeModalFormAddress } from '../Sections/dashboard/home/HomeModalFormAddress';

// import { HomeModalFormContent } from '../Sections/dashboard/home/HomeModalFormContent';

export default function Home() {
  return (
    <Page title="Darum | Home">
      <Container>
        <HomeHeader />
        <ActionCards />
        <TableComponent
          tableBaseUrl="v1/request/orders"
          tabelDescription="Delivery History"
          tableHeader={[
            'timestamp',
            'senderName',
            'sourceName',
            'recipientName',
            'destinationName',
            'rideStatus',
            'priceRange',
          ]}
          mappingData={{
            timestamp: 'Date',
            senderName: 'Sender',
            sourceName: 'Sender Address',
            recipientName: 'Receiver',
            destinationName: 'Receiver Address',
            rideStatus: 'Status',
            priceRange: 'Amount',
          }}
        />
        <OrdersProvider>
          <ModalProvider>
            <Modal>
              {/* <HomeModalFormContent /> */}
              <HomeModalFormAddress />
            </Modal>
          </ModalProvider>
        </OrdersProvider>
      </Container>
    </Page>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem 0;
`;
