import React from 'react';

import styled from '@emotion/styled';

interface IBanner {
  totalEstimatedPrice: string;
  walletBalance: string;
}
export const Banner: React.FC<IBanner> = ({
  totalEstimatedPrice,
  walletBalance,
}) => {
  return (
    <BannerContainer>
      <TotalPrice>{`${
        totalEstimatedPrice ||
        0.0?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN',
        })
      }`}</TotalPrice>
      <WalletBalance>Wallet Balance : {`${walletBalance}`}</WalletBalance>
    </BannerContainer>
  );
};

export const SuccessBanner = () => {
  return (
    <SuccessBannerContainer>
      <SuccessColumnStack>
        <SuccessBannerTitle>Dispatch Booked Successfully</SuccessBannerTitle>
        <SuccessBannerDescription>
          We have received your dispatch request and a rider will be assigned to
          you shortly
        </SuccessBannerDescription>
      </SuccessColumnStack>
      <Img src="/static/img/orderBannerElement.png" />
    </SuccessBannerContainer>
  );
};

// styles
const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey2};
`;

const TotalPrice = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primaryText};
`;

const WalletBalance = styled.div`
  font-weight: 500;
`;

const SuccessColumnStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuccessBannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.brandBackdrop};
  padding: 1.25rem;
  border-radius: 0.5rem;
  gap: 1rem;
`;

const SuccessBannerTitle = styled.div`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.primaryText};
`;

const SuccessBannerDescription = styled.div`
  font-size: 0.875rem;
`;

const Img = styled.img`
  width: 6.5rem;
`;
