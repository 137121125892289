import axios from 'axios';
import { createContext, useContext } from 'react';

import { configSetting } from '../config';
import AuthContext from './AuthContext';

const FetchContext = createContext<IFetchContext | null>(null);
const { Provider } = FetchContext;

export const FetchProvider: React.FC<any> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const authAxios = axios.create({
    baseURL: configSetting.apiUrl, //'https://testsuite.darum.com.ng',
    // baseURL: process.env.REACT_APP_API_URI,
    // baseURL: 'http://testsuite.darum.com.ng',
    headers: {
      Authorization: `Basic ${authContext?.authState.token}`,
    },
  });

  return (
    <Provider
      value={{
        authAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export default FetchContext;

interface IFetchContext {
  authAxios: any;
}
