import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../../Components/Button';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import { HideableTextFormField } from '../../../Components/FormCom/HideableTextFormField';
import FetchContext from '../../../Context/FetchContext';
import {
  UpdatePaswdDataType,
  updatePaswdInitialValues,
  UpdatePaswdSchema,
} from '../../../Models/settings/Security';

export const Security = () => {
  const navigate = useNavigate();
  const fetchContext = useContext(FetchContext);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: UpdatePaswdDataType) => {
    try {
      setLoadingSpinner(true);
      await fetchContext?.authAxios.post('v1/business/password', {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      toast.success('Password reset succesfully');
      navigate('/account/signin');
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container>
      <Label>Update Password</Label>

      <FormComponent
        initialValues={updatePaswdInitialValues}
        schema={UpdatePaswdSchema}
        onSubmit={onSubmit}
      >
        <HideableTextFormField
          label="Current Password"
          name="currentPassword"
          type="password"
          placeholder="Current Password"
          className={'spanTwo'}
        />
        <HideableTextFormField
          label="New Password"
          name="newPassword"
          type="password"
          placeholder="New Password"
          className={'spanTwo'}
        />
        <HideableTextFormField
          label="Confirm New Password"
          name="confirmNewPassword"
          type="password"
          placeholder="Confirm New Password"
          className={'spanTwo'}
        />

        <FormSubmitButton
          className="spanTwo"
          type="submit"
          showIcon={loadingSpinner}
        >
          Update Password
        </FormSubmitButton>
      </FormComponent>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 1.25rem;
  width: 27rem;
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
  /* border: 2px solid green; */
`;
