import { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

import AuthContext from '../../Context/AuthContext';
import { fetchAddress } from '../../redux/slices/addressSlice';
import { fetchBalance } from '../../redux/slices/walletSlice';
import { fetchWebhookUrl } from '../../redux/slices/webhookSlice';
import { useAppDisPatch } from '../../redux/store';
import { publicFetch } from '../../utils';
import { Header } from './header';
import { Nav } from './nav';

export const DashboardLayout = () => {
  const dispatch = useAppDisPatch();
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  // const [open, setOpen] = useState(true);

  useEffect(() => {
    if (authContext?.isAuthenticated()) {
      dispatch(fetchBalance());
      dispatch(fetchWebhookUrl());
      dispatch(fetchAddress());
    }
  }, [authContext?.authState.user, authContext, dispatch]);

  if (
    authContext?.isAuthenticated() &&
    !authContext?.authState.user?.verified
  ) {
    const handleResentOtp = () => {
      publicFetch
        .post(`auth/otp/resend?userId=${authContext.authState.user?.userId}`)
        .catch((error: any) => {
          const { data } = error.response;
          console.error(data.message);
        });
    };
    handleResentOtp();
    return (
      <Navigate
        to="/account/verify-otp"
        replace={true}
        state={{
          data: {
            email: authContext.authState.user?.email,
            userId: authContext.authState.user?.userId,
          },
        }}
      />
    );
  }

  return (
    <StyledRoot>
      {authContext?.isAuthenticated() ? (
        <>
          <Nav
            open={open}
            toggleNav={() => {
              setOpen(!open);
            }}
          />
          <NonNav>
            <Header navState={open} />
            <Main>
              <Outlet />
            </Main>
          </NonNav>
        </>
      ) : (
        <Navigate to="/account/signin" replace={true} />
      )}
    </StyledRoot>
  );
};

const StyledRoot = styled.div`
  display: flex;
  overflow: hidden;
  height: 100vh;
  /* border: 2px solid blue; */
  background-color: ${({ theme }) => theme.palette.primaryBgColor};
`;

const Main = styled.main`
  flex-direction: column;
  display: flex;
  padding: 1.5rem 2.5rem;
  padding-bottom: 4rem;
  /* border: 2px solid yellow; */

  @media screen and (max-width: 540px) {
    padding: 0 1rem;
  }

  @media screen and (min-width: 1600px) {
    min-width: 1400px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const NonNav = styled.section`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100%;
`;
