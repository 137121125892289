import styled from '@emotion/styled';

import { Card } from '../../../Components/Card';
import { useAppSelector } from '../../../redux/store';
import { FundWalletForm } from './FundWalletForm';

export const PaymentsCard = () => {
  const { walletBalance } = useAppSelector((state) => state.wallet);
  return (
    <Container>
      <Card
        boldLabel
        label="Wallet Balance"
        focusData={`${walletBalance?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN',
        })}`}
        actionLabel="Fund Wallet"
        iconSrc="/static/svg/wallet.svg"
        to="/payments"
      />
      <FundWalletForm />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1.5rem;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;
