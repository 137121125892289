import React from 'react';

export const SupportIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 11.75L5.19356 14.0853C4.87184 14.411 4.71098 14.5739 4.57271 14.5854C4.45276 14.5953 4.33531 14.5471 4.25697 14.4557C4.16667 14.3504 4.16667 14.1215 4.16667 13.6637V12.4937C4.16667 12.083 3.83031 11.7858 3.4239 11.7262V11.7262C2.44031 11.5822 1.66783 10.8097 1.52376 9.8261C1.5 9.66391 1.5 9.47039 1.5 9.08333V5.6C1.5 4.33988 1.5 3.70982 1.74524 3.22852C1.96095 2.80516 2.30516 2.46095 2.72852 2.24524C3.20982 2 3.83988 2 5.1 2H10.65C11.9101 2 12.5402 2 13.0215 2.24524C13.4448 2.46095 13.789 2.80516 14.0048 3.22852C14.25 3.70982 14.25 4.33988 14.25 5.6V8.75M14.25 17L12.6177 15.8652C12.3882 15.7056 12.2735 15.6259 12.1487 15.5693C12.0378 15.5191 11.9213 15.4826 11.8017 15.4606C11.6669 15.4357 11.5271 15.4357 11.2477 15.4357H9.9C9.05992 15.4357 8.63988 15.4357 8.31901 15.2722C8.03677 15.1284 7.8073 14.8989 7.66349 14.6167C7.5 14.2958 7.5 13.8758 7.5 13.0357V11.15C7.5 10.3099 7.5 9.88988 7.66349 9.56901C7.8073 9.28677 8.03677 9.0573 8.31901 8.91349C8.63988 8.75 9.05992 8.75 9.9 8.75H14.1C14.9401 8.75 15.3601 8.75 15.681 8.91349C15.9632 9.0573 16.1927 9.28677 16.3365 9.56901C16.5 9.88988 16.5 10.3099 16.5 11.15V13.1857C16.5 13.8846 16.5 14.2341 16.3858 14.5097C16.2336 14.8773 15.9416 15.1693 15.574 15.3215C15.2984 15.4357 14.9489 15.4357 14.25 15.4357V17Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
