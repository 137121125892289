import React, { useState } from 'react';

import styled from '@emotion/styled';

import { CheveronDown } from '../../../Components/Icons/CheveronDown';
import { CheveronUp } from '../../../Components/Icons/CheveronUp';
import { ModalProvider } from '../../../Context/ModalContext';
import { OrderSummaryCard } from './OrderSummaryCard';

interface IOrder {
  pickupLocation: string;
  deliveryLocation: string;
  estimatedCost: string;
  receiverFullName: string;
  receiverPhoneNo: string;
  packageType: string;
  assignRider: string;
  deliverDate: string;
  deliverTime: string;
  index: number;
  ordersCreated: number;
  setShowBookADispatchPage: (...args: any) => void;
}

export const CompondOrderSummaryCard: React.FC<IOrder> = ({
  pickupLocation,
  deliveryLocation,
  estimatedCost,
  receiverFullName,
  receiverPhoneNo,
  packageType,
  // assignRider,
  // deliverDate,
  // deliverTime,
  ordersCreated,
  index,
  setShowBookADispatchPage,
}) => {
  const [reveal, setReveal] = useState(false);
  return (
    <Container className={reveal ? 'reveal' : ''}>
      <ModalProvider>
        <OrderSummaryCard
          pickupLocation={pickupLocation}
          deliveryLocation={deliveryLocation}
          estimatedCost={estimatedCost}
          receiverFullName={receiverFullName}
          index={index}
          ordersCreated={ordersCreated}
          setShowBookADispatchPage={setShowBookADispatchPage}
        />
      </ModalProvider>
      <RevealContentsArea>
        <ExtraDetailsArea
          onClick={() => {
            setReveal(!reveal);
          }}
        >
          <div>Receiver’s details</div>
          {reveal ? <CheveronUp /> : <CheveronDown />}
        </ExtraDetailsArea>
        <HideableContent className={reveal ? 'reveal' : ''}>
          <KeyPair>
            <Label>Receivers Name</Label>
            <Value>{receiverFullName}</Value>
          </KeyPair>
          <KeyPair>
            <Label>Receiver’s Phone Number</Label>
            <Value>{receiverPhoneNo}</Value>
          </KeyPair>
          <KeyPair>
            <Label>Package Type</Label>
            <Value>{packageType}</Value>
          </KeyPair>
          {/* <KeyPair>
            <Label>Rider</Label>
            <Value>{assignRider}</Value>
          </KeyPair>
          <KeyPair>
            <Label>Delivery Date</Label>
            <Value>{deliverDate}</Value>
          </KeyPair>
          <KeyPair>
            <Label>Delivery Time</Label>
            <Value>{deliverTime}</Value>
          </KeyPair> */}
        </HideableContent>
      </RevealContentsArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey2};
  box-shadow: 0px 1px 2px 0px rgba(158, 77, 46, 0.04);
  width: '100%';
  gap: 1rem;
  grid-row: span 15;
  /* break-inside: avoid; */
  &.reveal {
    grid-row: span 25;
  }

  &:nth-of-type(odd) {
    /* border: 2px solid blue; */
    grid-column: 1/2;
  }

  &:nth-of-type(even) {
    /* border: 2px solid red; */
    grid-column: 2/3;
  }
`;

const HideableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 0;
  overflow-y: auto;
  padding-top: 0rem;
  background-color: #fff;

  &.reveal {
    padding-top: 1rem;
    height: fit-content;
    padding-bottom: 1rem;
  }
`;

const ExtraDetailsArea = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const KeyPair = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  font-size: 0.875rem;
`;

const Value = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

const RevealContentsArea = styled.div`
  display: flex;
  flex-direction: column;
`;
