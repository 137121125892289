import React from 'react';

import styled from '@emotion/styled';

import { InternalLink } from '../Links';

interface IEmptyTableComponent {
  children: string;
  link: string;
}

export const EmptyTableComponent: React.FC<IEmptyTableComponent> = ({
  children,
  link,
}) => {
  return (
    <EmptyTable>
      <img src="/static/svg/open-box.svg" alt="open box" />
      <div>Nothing to see here</div>
      <InternalLink to={link}>{children}</InternalLink>
    </EmptyTable>
  );
};

const EmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    text-align: center;
  }
`;
