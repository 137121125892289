import React from 'react';

export const OrdersIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.375 6.5V12.65C15.375 13.9101 15.375 14.5402 15.1298 15.0215C14.914 15.4448 14.5698 15.789 14.1465 16.0048C13.6652 16.25 13.0351 16.25 11.775 16.25H6.225C4.96488 16.25 4.33482 16.25 3.85352 16.0048C3.43016 15.789 3.08595 15.4448 2.87024 15.0215C2.625 14.5402 2.625 13.9101 2.625 12.65V6.5M2.7 2.75H15.3C15.72 2.75 15.9301 2.75 16.0905 2.83175C16.2316 2.90365 16.3464 3.01839 16.4183 3.15951C16.5 3.31994 16.5 3.52996 16.5 3.95V5.3C16.5 5.72004 16.5 5.93006 16.4183 6.09049C16.3464 6.23161 16.2316 6.34635 16.0905 6.41825C15.9301 6.5 15.72 6.5 15.3 6.5H2.7C2.27996 6.5 2.06994 6.5 1.90951 6.41825C1.76839 6.34635 1.65365 6.23161 1.58175 6.09049C1.5 5.93006 1.5 5.72004 1.5 5.3V3.95C1.5 3.52996 1.5 3.31994 1.58175 3.15951C1.65365 3.01839 1.76839 2.90365 1.90951 2.83175C2.06994 2.75 2.27996 2.75 2.7 2.75ZM7.2 9.125H10.8C11.22 9.125 11.4301 9.125 11.5905 9.20675C11.7316 9.27865 11.8463 9.39339 11.9183 9.53451C12 9.69494 12 9.90496 12 10.325V10.925C12 11.345 12 11.5551 11.9183 11.7155C11.8463 11.8566 11.7316 11.9713 11.5905 12.0433C11.4301 12.125 11.22 12.125 10.8 12.125H7.2C6.77996 12.125 6.56994 12.125 6.40951 12.0433C6.26839 11.9713 6.15365 11.8566 6.08175 11.7155C6 11.5551 6 11.345 6 10.925V10.325C6 9.90496 6 9.69494 6.08175 9.53451C6.15365 9.39339 6.26839 9.27865 6.40951 9.20675C6.56994 9.125 6.77996 9.125 7.2 9.125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
