import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IsignInData } from '../Models/auth/SignIn';

const AuthContext = createContext<IAuthContext | null>(null);
const { Provider } = AuthContext;

export const AuthProvider: React.FC<any> = ({ children }) => {
  const navigate = useNavigate();
  // TODO:: Move this to useEffect latter
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  const [authState, setAuthState] = useState<IsignInData>({
    token,
    user: user ? JSON.parse(user) : undefined,
  });

  const setAutInfo = ({ token, user }: IsignInData) => {
    localStorage.setItem('token', token!);
    localStorage.setItem('user', JSON.stringify(user));
    setAuthState({
      token,
      user,
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuthState({ token: undefined, user: undefined });
    navigate('account/signin');
  };

  const isAuthenticated = () => {
    if (!authState.token) {
      // return true;
      return false;
    }
    return true;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: IsignInData) => setAutInfo(authInfo),
        isAuthenticated,
        logout,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthContext;

interface IAuthContext {
  authState: IsignInData;
  setAuthState: (authInfo: IsignInData) => void;
  isAuthenticated: () => boolean;
  logout: () => void;
}
