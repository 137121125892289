import styled from '@emotion/styled';

import { Modal } from '../../../../Components/Modal';
import { ModalProvider } from '../../../../Context/ModalContext';
import { OrdersProvider } from '../../../../Context/OrdersContext';
import { useAppSelector } from '../../../../redux/store';
import { AddressCard } from './AddressCard';
import { ProfileModalContent } from './ProfileModalContent';

// Profile
export const Profile = () => {
  const { defaultAddress, otherAddresses } = useAppSelector(
    (state) => state.address
  );

  return (
    <ModalProvider>
      <ProfileWrapper>
        <AddressCard
          categoryName="Primary Address"
          defaultAddress={true}
          addressData={defaultAddress}
        />
        <AddressCard
          categoryName="Address Book"
          showAddLocation={true}
          addressData={otherAddresses}
        />
        <OrdersProvider>
          <Modal>
            <ProfileModalContent />
          </Modal>
        </OrdersProvider>
      </ProfileWrapper>
    </ModalProvider>
  );
};

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
