import { HomeIcon } from '../../../Components/Icons/HomeIcon';
import { OrdersIcon } from '../../../Components/Icons/OrdersIcon';
import { PaymentsIcon } from '../../../Components/Icons/PaymentsIcon';
import { SettingsIcon } from '../../../Components/Icons/SettingsIcon';
import { SupportIcon } from '../../../Components/Icons/SupportIcon';

export const navConfig = [
  {
    title: 'Home',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    title: 'Orders',
    path: '/orders',
    icon: <OrdersIcon />,
  },
  {
    title: 'Payments',
    path: '/payments',
    icon: <PaymentsIcon />,
  },

  {
    title: 'Settings',
    path: '/settings',
    icon: <SettingsIcon />,
  },
  {
    title: 'Support',
    path: '/support',
    icon: <SupportIcon />,
  },
];
