import styled from '@emotion/styled';

import { SegmentContainer } from './SegmentContainer';

export const Contact = () => {
  return (
    <SegmentContainer title="Contact us">
      <ContactCellWrapper>
        <ContactCell>
          <img src="/static/svg/location-sm.svg" alt="location" />
          <div>23, Hughes avenue, Alagomeji, Yaba, Lagos</div>
        </ContactCell>
        <ContactCell>
          <img src="/static/svg/contact.svg" alt="contact" />
          <div>07000032786</div>
        </ContactCell>
        <ContactCell>
          <img src="/static/svg/mail.svg" alt="mail" />
          <div>info@darum.com.ng</div>
        </ContactCell>
      </ContactCellWrapper>
    </SegmentContainer>
  );
};

const ContactCellWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  justify-content: space-between;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const ContactCell = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;
