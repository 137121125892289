import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

export const NavItem: React.FC<INavItem> = ({ icon, path, children }) => {
  return (
    <StyledNavLink
      className={(navData) => (navData.isActive ? 'active' : 'none')}
      to={path}
    >
      {icon}
      {children}
    </StyledNavLink>
  );
};

const StyledNavLink = styled(RouterLink)`
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.tertiaryText};
  font-size: 0.875rem;
  width: 100%;
  font-weight: 700;
  align-items: center;
  border-bottom: 2px solid transparent;

  @media screen and (max-width: 480px) {
    padding-left: 0;
  }

  &:link {
    color: ${({ theme }) => theme.palette.tertiaryText};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.secondaryTextColor};
  }

  &.active {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.brandPrimary};
    border-bottom: 2px solid ${({ theme }) => theme.palette.brandPrimary};
  }
`;

interface INavItem {
  icon: React.ReactNode;
  children: string;
  path: string;
}
