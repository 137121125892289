import { useField } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';

import OrdersContext from '../../Context/OrdersContext';
import { useAppSelector } from '../../redux/store';
import { CheveronDown } from '../Icons/CheveronDown';
import {
  FieldErrorInfo,
  IInputField,
  InputContainer,
  InputLabel,
  InputWrapper,
  Select,
  SelectDropDownIcon,
} from './FormField';

const DefaultAddressSelectField: React.FC<
  Omit<IInputField, 'placeholder' | 'type'>
> = ({ label, className, ...props }) => {
  const orderContext = useContext(OrdersContext);
  const { defaultAddress, otherAddresses } = useAppSelector(
    (state) => state.address
  );
  const inputRef = useRef<any>(null);
  const [field, meta] = useField(props);

  useEffect(() => {
    orderContext?.setDeliveryEstimate(' - -');
    const pickupLocationData = [...defaultAddress, ...otherAddresses].find(
      (data) => inputRef.current.value === String(data.id)
    );

    const coordinate_info = {
      place_id: pickupLocationData?.placeId,
      primaryText: pickupLocationData?.primaryText,
      secondaryText: pickupLocationData?.secondaryText,
      lat: String(pickupLocationData?.lat),
      lng: String(pickupLocationData?.lng),
      formatted_address: `${pickupLocationData?.primaryText} ${pickupLocationData?.secondaryText}`,
    };

    orderContext?.dispatch({ type: props.name, payload: coordinate_info });
    // eslint-disable-next-line
  }, [inputRef.current?.value]);

  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <Select ref={inputRef} {...field} {...props} />
        <SelectDropDownIcon>
          <CheveronDown />
        </SelectDropDownIcon>
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export default DefaultAddressSelectField;
