import { useLocation } from 'react-router-dom';

import { AuthLayout } from '../../Components/AuthLayout';
import Page from '../../Components/Page';
import { VerifySignUpOtp } from '../../Sections/auth/VerifySignUpOtp';

export default function SignUpVerification() {
  const location = useLocation();
  const userEmail = location.state?.data.email;

  return (
    <Page title="Darum | Verify Signup">
      <AuthLayout
        formHeaderTitle="Verify your account"
        formHeaderDescription={
          <span>
            We sent a One-Time Password (OTP) to <b>{userEmail}</b> to
            authenticate your sign up.{' '}
          </span>
        }
      >
        <VerifySignUpOtp />
      </AuthLayout>
    </Page>
  );
}
