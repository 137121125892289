import { useContext, useEffect, useState } from 'react';

// import toast from 'react-hot-toast';
import styled from '@emotion/styled';

import FetchContext from '../../Context/FetchContext';
import { TableFilterDataType } from '../../Models/table/TableFilter';
import {
  firstDayOfLastMonth,
  getStartOfMonth,
  lastDayOfLastMonth,
  sevenDaysAgo,
  todaysDate,
} from '../../utils';
import { HoverDropDown } from '../DropDown';
import { DownChevron } from '../Icons/DownChevron';
import { FormFilter } from './FormFilter';
import { Table } from './Table';

interface ImappingData {
  [key: string]: string;
}

interface ITableComponent {
  extraQuery?: string;
  mappingData?: ImappingData;
  tableBaseUrl: string;
  tableHeader: string[];
  tabelDescription?: string;
}

interface ITableFilterButton {
  children: any;
}

const TableFilterButton: React.FC<ITableFilterButton> = ({ children }) => {
  return <TableFilterButtonLabel>{children}</TableFilterButtonLabel>;
};

export const TableComponent: React.FC<ITableComponent> = ({
  tableBaseUrl,
  tableHeader,
  mappingData,
  tabelDescription,
  extraQuery = '',
}) => {
  const fetchContext = useContext(FetchContext);
  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [tdata, settData] = useState<any>([{}]);
  const [showTableLoadingIcon, setShowTableLoadingIcon] = useState(true);
  const [filter, setFilter] = useState('All Time');
  const [customFilterData, setCustomFilterData] = useState<{
    startDate: any;
    endDate: any;
  }>({
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    setPage(1);
  }, [filter, customFilterData]);

  useEffect(() => {
    const itemsPerPage = 10;

    const fetchData = async () => {
      try {
        setShowTableLoadingIcon(true);
        let tableData;
        switch (filter) {
          case 'Today':
            tableData = await fetchContext?.authAxios.get(
              `${tableBaseUrl}?page=${page}&startDate=${todaysDate()}&endDate=${todaysDate()}&size=${itemsPerPage}${extraQuery}`
            );
            break;
          case 'All Time':
            tableData = await fetchContext?.authAxios.get(
              `${tableBaseUrl}?page=${page}&size=${itemsPerPage}`
            );
            break;
          case 'Last 7 days':
            tableData = await fetchContext?.authAxios.get(
              `${tableBaseUrl}?page=${page}&startDate=${sevenDaysAgo()}&endDate=${todaysDate()}&size=${itemsPerPage}${extraQuery}`
            );
            break;
          case 'This Month':
            tableData = await fetchContext?.authAxios.get(
              `${tableBaseUrl}?page=${page}&startDate=${getStartOfMonth()}&endDate=${todaysDate()}&size=${itemsPerPage}`
            );
            break;

          case 'Last Month':
            tableData = await fetchContext?.authAxios.get(
              `${tableBaseUrl}?page=${page}&startDate=${firstDayOfLastMonth()}&endDate=${lastDayOfLastMonth()}&size=${itemsPerPage}${extraQuery}`
            );
            break;
          case 'Custom':
            tableData = await fetchContext?.authAxios.get(
              `${tableBaseUrl}?page=${page}&startDate=${customFilterData.startDate}&endDate=${customFilterData.endDate}&size=${itemsPerPage}${extraQuery}`
            );
            break;
        }
        settData(tableData.data.data.records);
        setEndPage(tableData.data.data.totalPages);
        setShowTableLoadingIcon(false);
      } catch (error: any) {
        settData([]);
        setShowTableLoadingIcon(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [page, filter, customFilterData]);

  const onSubmitTableFilter = (values: TableFilterDataType, actions: any) => {
    setFilter('Custom');
    setCustomFilterData({
      startDate: values.startDate,
      endDate: values.endDate,
    });

    actions.resetForm({ values: { startDate: '', endDate: '' } });
  };
  return (
    <TableContainer>
      <TableToolBox>
        <TableTitle>{tabelDescription}</TableTitle>
        <HoverDropDown
          label={
            <TableFilterButton>
              Filter <DownChevron />
            </TableFilterButton>
          }
        >
          {
            <FormFilter
              filter={filter}
              setFilter={setFilter}
              onSubmitTableFilter={onSubmitTableFilter}
            />
          }
        </HoverDropDown>
      </TableToolBox>
      <Table
        showTableLoadingIcon={showTableLoadingIcon}
        setShowTableLoadingIcon={setShowTableLoadingIcon}
        page={page}
        endPage={endPage}
        tData={tdata}
        clickLeft={() => setPage(page === 0 ? page : page - 1)}
        clickRight={() => setPage(endPage > page ? page + 1 : page)}
        tableHeader={tableHeader}
        mappingData={mappingData}
      />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  display: grid;
  gap: 1.5rem;

  @media screen and (max-width: 480px) {
    overflow-x: auto;
  }
`;

const TableToolBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableTitle = styled.div`
  color: black;
  font-size: 1.12rem;
  font-weight: 700;
`;

const TableFilterButtonLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  height: 48px;
  font-weight: 700;
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
`;
