import { Form, Formik } from 'formik';

import styled from '@emotion/styled';

interface IFormComponent {
  initialValues: any;
  schema: any;
  onSubmit: any;
  children: any;
  className?: any;
}

export const FormComponent: React.FC<IFormComponent> = ({
  initialValues,
  schema,
  onSubmit,
  children,
  className,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <StyledForm className={className}>{children}</StyledForm>
    </Formik>
  );
};

const StyledForm = styled(Form)`
  /* border: 2px solid orange; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    /* border: 2px solid yellow; */
  }

  & > .spanTwo {
    /* border: 2px solid green; */
    grid-column: span 2;
  }

  & > .margin {
    /* border: 2px solid green; */
    grid-column: span 2;
    margin-left: 15%;
    margin-right: 15%;

    @media (max-width: 480px) {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  &.horizontalForm {
    grid-template-columns: 4fr 1fr;
    align-items: flex-start;

    & > * {
      height: fit-content;
      width: 100%;
    }
    & > button {
      margin-top: 1.5rem;
    }
  }

  /* 
  &.customFormLayout {
    display: flex;
    flex-direction: column;
  } */
`;
