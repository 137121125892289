import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Global, ThemeProvider } from '@emotion/react';

import App from './App';
import ErrorBoundary from './Components/ErrorBounday';
import { AuthProvider } from './Context/AuthContext';
import { FetchProvider } from './Context/FetchContext';
import GlobalStyles, { theme } from './globalStyles';
import store from './redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Global styles={GlobalStyles} />
            <AuthProvider>
              <FetchProvider>
                <App />
              </FetchProvider>
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </HelmetProvider>
);
