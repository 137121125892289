import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { configSetting } from '../../config';

interface IAddressStack {
  id: string;
  userId: string;
  locationName: string;
  profileName: string;
  phoneNumber: string;
  placeId: string;
  primaryText: string;
  secondaryText: string;
  lat: number;
  lng: number;
  defaultAddress: true;
}

interface IinitialState {
  addressStatus: string;
  addressError?: string | null;
  defaultAddress: IAddressStack[];
  otherAddresses: IAddressStack[];
}

const initialState: IinitialState = {
  addressStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  addressError: null,
  defaultAddress: [],
  otherAddresses: [],
};

export const fetchAddress = createAsyncThunk(
  'address/fetchAddress',
  async () => {
    try {
      const { data } = await axios.get(
        `${configSetting.apiUrl}v1/business/address`,
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const addAddress = createAsyncThunk(
  'address/addAddress',
  async (addressData: any) => {
    try {
      await axios.post(
        `${configSetting.apiUrl}v1/business/address`,
        { ...addressData },
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const setDefaultAddress = createAsyncThunk(
  'address/setDefaultAddress',
  async (addressData: any) => {
    try {
      await axios.post(
        `${configSetting.apiUrl}v1/business/address/${addressData.id}/default`,
        {
          profileName: addressData.profileName,
          placeId: addressData.placeId,
          primaryText: addressData.primaryText,
          secondaryText: addressData.secondaryText,
          phoneNumber: addressData.phoneNumber,
          locationName: addressData.locationName,
          lat: addressData.lat,
          lng: addressData.lng,
        },
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const updateAddress = createAsyncThunk(
  'address/updateAddress',
  async (addressData: any) => {
    try {
      await axios.post(
        `${configSetting.apiUrl}v1/business/address/${addressData.id}`,
        { ...addressData },
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // get address
    builder.addCase(fetchAddress.pending, (state, action) => {
      state.addressStatus = 'loading';
    });
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      state.addressStatus = 'succeeded';
      const defaultAddress = [];
      const otherAddresses = [];
      if (action.payload.data) {
        for (let address of action.payload.data) {
          if (address.defaultAddress) {
            defaultAddress.push(address);
          } else {
            otherAddresses.push(address);
          }
        }
        state.defaultAddress = defaultAddress;
        state.otherAddresses = otherAddresses;
      }
    });
    builder.addCase(fetchAddress.rejected, (state, action) => {
      state.addressStatus = 'failed';
      state.addressError = action.error.message;
    });

    // add address
    builder.addCase(addAddress.pending, (state, action) => {
      state.addressStatus = 'loading';
    });
    builder.addCase(addAddress.fulfilled, (state, action) => {
      state.addressStatus = 'succeeded';
    });
    builder.addCase(addAddress.rejected, (state, action) => {
      state.addressStatus = 'failed';
      state.addressError = action.error.message;
    });

    // set default address
    builder.addCase(setDefaultAddress.pending, (state, action) => {
      state.addressStatus = 'loading';
    });
    builder.addCase(setDefaultAddress.fulfilled, (state, action) => {
      state.addressStatus = 'succeeded';
    });
    builder.addCase(setDefaultAddress.rejected, (state, action) => {
      state.addressStatus = 'failed';
      state.addressError = action.error.message;
    });

    // update address
    builder.addCase(updateAddress.pending, (state, action) => {
      state.addressStatus = 'loading';
    });
    builder.addCase(updateAddress.fulfilled, (state, action) => {
      state.addressStatus = 'succeeded';
    });
    builder.addCase(updateAddress.rejected, (state, action) => {
      state.addressStatus = 'failed';
      state.addressError = action.error.message;
    });
  },
});

// export const {} = addressSlice.actions;
export default addressSlice.reducer;
