import React from 'react';

import styled from '@emotion/styled';

import { GoBackButton } from './Button';

interface IPageTitle {
  children: React.ReactNode;
  title?: string;
  backbtn?: boolean;
  btnCallBack?: (...args: any) => void;
}

export const PageTitle: React.FC<IPageTitle> = ({
  children,
  title,
  backbtn,
  btnCallBack = () => '',
}) => {
  return (
    <Container>
      <LabelArea>
        {backbtn && <GoBackButton btnCallBack={btnCallBack} />}
        <Label>{title}</Label>
      </LabelArea>
      {children}
    </Container>
  );
};

const Container = styled.div`
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > * {
    /* border: 2px solid lime; */
  }
`;

const LabelArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  /* border: 2px solid red; */
`;

const Label = styled.div`
  /* border: 2px solid red; */
  justify-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  grid-column: 2;
  color: ${({ theme }) => theme.palette.primaryText};
`;
