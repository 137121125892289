import { AuthLayout } from '../../Components/AuthLayout';
import Page from '../../Components/Page';
import { SignInArea } from '../../Sections/auth/SignInArea';

export default function SignIn() {
  return (
    <Page title="Darum | SignIn">
      <AuthLayout
        label="Do not have an account?"
        linkTitle="Sign up"
        linkPath="/account/signup"
        formHeaderTitle="Hello"
        formHeaderDescription="Sign in to your account"
        forgotPassword
      >
        <SignInArea />
      </AuthLayout>
    </Page>
  );
}
