import { useContext } from 'react';

import { FormSubmitButton } from '../../../Components/Button';
import { ModalContext } from '../../../Context/ModalContext';

const FundWalletToBookDispatchButton = () => {
  const modalContext = useContext(ModalContext);
  const showModal = () => {
    modalContext?.displayModal(true);
  };

  return (
    <FormSubmitButton className="mt1" onClick={showModal}>
      Fund Wallet to Book Dispatch
    </FormSubmitButton>
  );
};

export default FundWalletToBookDispatchButton;
