import { css, Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    palette: {
      primaryTextColor: string;
      secondaryTextColor: string;
      tertiaryTextColor: string;
      primaryBgColor: string;
      tertiaryBgColor: string;
      secondaryBgColor: string;
      primaryBorderColor: string;
      tableHoverColor: string;
      inputFieldFocusColor: string;
      errorTextColor: string;
      shadowColor: string;
      borderLineColor: string;

      // New styles
      brandBackdrop: string;
      brandDark: string;
      brandInactive: string;
      secondaryText: string;
      tertiaryText: string;
      brandPrimary: string;
      brandGrey: string;
      brandGrey2: string;
      primaryText: string;
      brandTableHeader: string;
      blackPrimary: string;
      blackTertiary: string;
      greyTertiary: string;
      warningPrimary: string;
      errorPrimary: string;
      warningBackdrop: string;
    };
  }
}

export const theme: Theme = {
  palette: {
    primaryTextColor: '#a3a3a3',
    secondaryTextColor: '#606060',
    tertiaryTextColor: '#f46f3b',
    primaryBgColor: '#f5f6f7',
    tertiaryBgColor: '#f46f3b',
    secondaryBgColor: '#ffffff',
    primaryBorderColor: '#e4e4e4',
    tableHoverColor: '#ededed',
    inputFieldFocusColor: '#EBF3FE',
    errorTextColor: '#FF0000',
    shadowColor: '#a9b0b1',
    borderLineColor: '#dddcdc',

    // New styles
    brandBackdrop: '#FEF8F5',
    brandDark: '#D9460C',
    brandInactive: '#FAB89E',
    secondaryText: '#504B49',
    tertiaryText: '#867C79',
    brandPrimary: '#F46F3B',
    brandGrey: '#DFE0E6',
    brandGrey2: '#F4F1F1',
    primaryText: '#2C2421',
    brandTableHeader: '#F5F1F0',
    blackPrimary: '#1F2130',
    blackTertiary: '#71748C',
    greyTertiary: '#F6F6F8',
    warningPrimary: '#F07C11',
    errorPrimary: '#C51004',
    warningBackdrop: '#F9F4EE',
  },
};

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    font-size: 1em;
  }
  html,
  body {
    height: 100%;
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'Manrope', 'Work Sans', sans-serif;
    font-weight: 500;
    color: #867c79;
  }
  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  #root,
  #__next {
    isolation: isolate;
  }
`;

export default GlobalStyles;
