import React from 'react';

import styled from '@emotion/styled';

interface IModalContentWrapper {
  width?: string;
  title: string;
  description?: string;
  children: React.ReactNode;
  className?: string;
  customController?: React.ReactNode;
}
export const SectionContainer: React.FC<IModalContentWrapper> = ({
  width,
  title,
  children,
  className,
  customController,
}) => {
  return (
    <Container width={width!} className={className}>
      <>
        <HeaderSection>
          <Label>{title}</Label>
          <CustomController>{customController}</CustomController>
        </HeaderSection>
        {children}
      </>
    </Container>
  );
};

interface IContainerWidth {
  width: string;
}

const Container = styled.div<IContainerWidth>`
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.25rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey2};
  box-shadow: 0px 1px 2px 0px rgba(158, 77, 46, 0.04);
  width: ${({ width }) => (width ? width : '100%')};
  gap: 1rem;

  & > .spanTwo {
    grid-column: 1/3;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
  }
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
  /* border: 2px solid green; */
  grid-column: 1/3;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.palette.blackPrimary};
  font-size: 1.125rem;
  font-weight: 700;
`;

const CustomController = styled.div`
  /* border: 2px solid red; */
`;
