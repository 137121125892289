import React, { useState } from 'react';
import toast from 'react-hot-toast';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../../Components/Button';
import { FormComponent } from '../../../Components/FormCom/FormComponent';
import { TextInputField } from '../../../Components/FormCom/FormField';
import {
  WebhookDataType,
  WebhookSchema,
} from '../../../Models/settings/Webhook';
import {
  changeWebhookUrl,
  updateWebhookUrl,
} from '../../../redux/slices/webhookSlice';
import { useAppDisPatch, useAppSelector } from '../../../redux/store';

export const Webhook = () => {
  const dispatch = useAppDisPatch();
  const { webhookUrl } = useAppSelector((state) => state.webhook);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: WebhookDataType, actions: any) => {
    try {
      setLoadingSpinner(true);
      await dispatch(updateWebhookUrl(values));
      dispatch(changeWebhookUrl(values.webhook));
      actions.resetForm({ values: { webhook: values.webhook } });
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container>
      <Label>Webhook</Label>

      <FormComponent
        initialValues={{ webhook: webhookUrl }}
        schema={WebhookSchema}
        onSubmit={onSubmit}
      >
        <TextInputField
          label="Webhook"
          name="webhook"
          type="text"
          placeholder="https://"
          className={'spanTwo '}
        />

        <FormSubmitButton
          className="spanTwo"
          type="submit"
          showIcon={loadingSpinner}
        >
          Save changes
        </FormSubmitButton>
      </FormComponent>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 1.25rem;
  width: 31.25rem;
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
`;
