export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  timeout: number
) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export const filterLogic = (dataArray: any, key: string, substring: string) => {
  return dataArray.filter((data: any) => {
    if (data[key].toLowerCase().includes(substring.toLowerCase())) {
      return true;
    }
    return false;
  });
};

// export const phoneRegex = /^(\+?234|0)?[789]\d{10}$/; // Regex pattern for Nigerian phone numbers +234 or 080
// export const phoneRegex = /^\+234\d{10}$/; // Regex pattern for Nigerian phone numbers starting with "+234"
export const phoneRegex =
  /^(?:(0))(?:(70|80|81|90|91|80|92|93|94|95|96|97|98|99)\d{8})$/;

export const convertDate = (inputDate: string) => {
  const date = new Date(inputDate);

  const formattedDate = date.toLocaleDateString('en-NG', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return formattedDate;
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const splitAddSpaceAndJoin = (word: string) => {
  return word.split(/(?=[A-Z])/).join(' ');
};

export const todaysDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

// export const todaysDate = () => {
//   return new Date().toISOString().slice(0, 10);
// };

export const sevenDaysAgo = () => {
  const today = new Date(); // Get the current date and time
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  // Format the date as YYYY-MM-DD
  const year = sevenDaysAgo.getFullYear();
  const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because months are zero-based
  const day = String(sevenDaysAgo.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getStartOfMonth = () => {
  const today = new Date(); // Get the current date and time
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Format the date as YYYY-MM-DD
  const year = firstDayOfMonth.getFullYear();
  const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because months are zero-based
  const day = String(firstDayOfMonth.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const firstDayOfLastMonth = () => {
  const today = new Date(); // Get the current date and time
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  // Format the date as YYYY-MM-DD
  const year = lastMonthStart.getFullYear();
  const month = String(lastMonthStart.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because months are zero-based
  const day = '01'; // The first day of the month

  return `${year}-${month}-${day}`;
};

export const lastDayOfLastMonth = () => {
  const today = new Date(); // Get the current date and time
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  // Format the date as YYYY-MM-DD
  const year = lastMonthEnd.getFullYear();
  const month = String(lastMonthEnd.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because months are zero-based
  const day = String(lastMonthEnd.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const truncateString = (str: string = '', maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + '...';
  }
};
