import styled from '@emotion/styled';

import { NavItem } from '../../../Components/NavItem';
import { navConfig } from './config';

export const HeaderNav = () => {
  return (
    <StyledNav>
      <NavElementContainer>
        {navConfig.map((data) => (
          <NavElement key={data.path}>
            <NavItem icon={data.icon} path={data.path}>
              {data.title}
            </NavItem>
          </NavElement>
        ))}
      </NavElementContainer>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  display: flex;
`;

const NavElementContainer = styled.div`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`;

const NavElement = styled.div`
  display: flex;
`;
