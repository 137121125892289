import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';

import { FormSubmitButton } from '../../../../Components/Button';
import { FormComponent } from '../../../../Components/FormCom/FormComponent';
import { TextInputField } from '../../../../Components/FormCom/FormField';
import { GooglePlacesInputField } from '../../../../Components/FormCom/GooglePlacesInputField';
import { ModalContentWrapper } from '../../../../Components/ModalContentWrapper';
import OrdersContext from '../../../../Context/OrdersContext';
import {
  AddressDataType,
  addressInitialValues,
  AddressSchema,
} from '../../../../Models/settings/Address';
import {
  addAddress,
  fetchAddress,
} from '../../../../redux/slices/addressSlice';
import { useAppDisPatch } from '../../../../redux/store';

export const ProfileModalContent = () => {
  const dispatch = useAppDisPatch();
  const addressContext = useContext(OrdersContext);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const onSubmit = async (values: AddressDataType, actions: any) => {
    try {
      setLoadingSpinner(true);
      const addressData = {
        profileName: values.profileName,
        placeId: addressContext?.state.address.place_id,
        primaryText: addressContext?.state.address.primaryText,
        secondaryText: addressContext?.state.address.secondaryText,
        phoneNumber: values.phoneNumber,
        locationName: values.locationName,
        lat: addressContext?.state.address.lat,
        lng: addressContext?.state.address.lng,
      };

      await dispatch(addAddress(addressData));
      dispatch(fetchAddress());
      actions.resetForm({ values: { ...addressInitialValues } });
      setLoadingSpinner(false);
    } catch (error: any) {
      setLoadingSpinner(false);
      if (error.response) {
        const { data } = error.response;
        toast.error(data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  return (
    <ModalContentWrapper title="Add address" width={'28rem'}>
      <FormComponent
        initialValues={addressInitialValues}
        schema={AddressSchema}
        onSubmit={onSubmit}
      >
        <TextInputField
          label="Location Name (Address Alias)"
          name="locationName"
          type="text"
          placeholder="Add Location Name"
          className={'spanTwo'}
        />

        <TextInputField
          label="Profile Name"
          name="profileName"
          type="text"
          placeholder="Add Profile Name"
          className={'spanTwo'}
        />
        <GooglePlacesInputField
          label="Address"
          name="address"
          type="text"
          placeholder="Add Address"
          className={'spanTwo'}
        />

        <TextInputField
          label="Phone Number"
          name="phoneNumber"
          type="text"
          placeholder="0811 1111 111"
          className={'spanTwo'}
        />
        <FormSubmitButton
          className="right mt1"
          type="submit"
          showIcon={loadingSpinner}
        >
          Add Address
        </FormSubmitButton>
      </FormComponent>
    </ModalContentWrapper>
  );
};
