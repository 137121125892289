import styled from '@emotion/styled';

import { CompanyLetterLogo } from '../../../Components/CompanyLogo';
import HamburgerIcon from '../../../Components/Icons/HamburgerIcon';
import { NavItem } from '../../../Components/NavItem';
import { navConfig } from './config';

export const Nav: React.FC<INav> = ({ open, toggleNav }) => {
  const handleNavMenuItemClick = (event: any) => {
    if (window.innerWidth <= 480) {
      toggleNav();
    }
  };

  return (
    <StyledNavContainer>
      <StyledNav className={open ? '' : 'close'}>
        <CompanyLetterLogo />
        <NavElementContainer>
          {navConfig.map((data) => (
            <NavElement onClick={handleNavMenuItemClick} key={data.path}>
              <NavItem icon={data.icon} path={data.path}>
                {data.title}
              </NavItem>
            </NavElement>
          ))}
        </NavElementContainer>
      </StyledNav>
      <NavButtonContainer className={open ? 'open' : ''} onClick={toggleNav}>
        {open ? '' : <HamburgerIcon />}
      </NavButtonContainer>
      <ClosingGreyBg
        className={open ? 'open' : ''}
        onClick={toggleNav}
      ></ClosingGreyBg>
    </StyledNavContainer>
  );
};

const StyledNavContainer = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 16rem;
  padding: 1.5rem 2.5rem;
  gap: 2.5rem;
  background-color: white;
  overflow-x: auto;
  transition: all 0.2s ease;
  /* border: 2px solid blue; */
  height: 100%;

  &.close {
    padding: 1.5rem 0px;
    width: 0px;
  }

  @media screen and (max-width: 480px) {
    position: absolute;
    z-index: 2000;
  }
`;

const ClosingGreyBg = styled.div`
  position: fixed;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000040;

  &.open {
    width: 100%;
    z-index: 1000;
  }
`;

const NavButtonContainer = styled.div`
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
    position: absolute;
    right: -38px;
    top: 28px;
    z-index: 40;
    transition: right 0.2s ease;

    &.open {
      right: -300px;
    }
  }
`;

const NavElementContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavElement = styled.div`
  display: flex;
  /* border: 2px solid blue; */
`;

interface INav {
  open: boolean;
  toggleNav: (...arg: any) => void;
}
