import * as Yup from 'yup';

import { phoneRegex } from '../../utils';

export const AddressSchema = Yup.object().shape({
  locationName: Yup.string().min(1).required('Please provide a location name'),
  profileName: Yup.string().min(1).required('Please provide a profile name'),
  address: Yup.string().min(1).required('Please provide a address'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Invalid Nigerian phone number')
    .required('Phone number is required'),
});

export type AddressDataType = Yup.InferType<typeof AddressSchema>;

type InitialValues = {
  locationName: string;
  profileName: string;
  address: string;
  phoneNumber: string;
};

export const addressInitialValues: InitialValues = {
  locationName: '',
  profileName: '',
  address: '',
  phoneNumber: '',
};
