import React, { useState } from 'react';

import styled from '@emotion/styled';

import { ModalProvider } from '../../../Context/ModalContext';
import { useAppSelector } from '../../../redux/store';
import { OrderSummaryCard } from './OrderSummaryCard';

interface IOrderSummaryCard {
  width?: string;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;
  setShowBookADispatchPage: (...args: any) => void;
}
export const OrderSummaryCardController: React.FC<IOrderSummaryCard> = ({
  width,
  title,
  setShowBookADispatchPage,
}) => {
  const { ordersStack, ordersCreated } = useAppSelector(
    (state) => state.orders
  );

  const [orderDataIndex, setOrderDataIndex] = useState(0);

  const orderData = ordersStack[orderDataIndex];

  return (
    <Container width={width}>
      <HeaderSection>
        <Label>Drop-off summary</Label>
        <ControlsArea>
          <Img
            src="/static/svg/leftCheveron.svg"
            onClick={() => {
              orderDataIndex > 0 && setOrderDataIndex(() => orderDataIndex - 1);
            }}
          />
          <Img
            src="/static/svg/rightCheveron.svg"
            onClick={() => {
              orderDataIndex < ordersCreated - 1 &&
                setOrderDataIndex(() => orderDataIndex + 1);
            }}
          />
        </ControlsArea>
      </HeaderSection>
      <ModalProvider>
        <OrderSummaryCard
          pickupLocation={orderData?.pickupLocation || ''}
          deliveryLocation={orderData?.deliveryLocation || ''}
          estimatedCost={orderData?.estimatedCost || ''}
          receiverFullName={orderData?.receiverFullName || ''}
          index={orderDataIndex}
          ordersCreated={ordersCreated}
          setShowBookADispatchPage={setShowBookADispatchPage}
        />
      </ModalProvider>
    </Container>
  );
};

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column: 1/3;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.palette.blackPrimary};
  font-size: 1.125rem;
  font-weight: 700;
`;

const ControlsArea = styled.div`
  display: flex;
`;

const Img = styled.img`
  cursor: pointer;
`;

const Container = styled.div<Pick<IOrderSummaryCard, 'width'>>`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.brandGrey2};
  box-shadow: 0px 1px 2px 0px rgba(158, 77, 46, 0.04);
  width: ${({ width }) => (width ? width : '100%')};
  gap: 1rem;
`;
