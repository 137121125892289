import React from 'react';

import styled from '@emotion/styled';

const Whatsapp = () => {
  return (
    <WhatsAppIconContainer>
      <a
        aria-label="Chat on WhatsApp"
        href="https://wa.me/2349169800212"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="Chat on WhatsApp" src="/static/svg/whatsapp.svg" />
      </a>
    </WhatsAppIconContainer>
  );
};

export default Whatsapp;

const WhatsAppIconContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 64px;
`;
