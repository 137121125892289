import axios from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { configSetting } from '../../config';

interface IInitailState {
  webhookStatus: string;
  webhookUrl: string;
  webhookError?: string | null;
}

const initialState: IInitailState = {
  webhookStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  webhookUrl: '',
  webhookError: null,
};

export const fetchWebhookUrl = createAsyncThunk(
  'webhook/fetchWebhookUrl',
  async () => {
    try {
      const { data } = await axios.get(
        `${configSetting.apiUrl}v1/business/webhook`,
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

interface IWebhookData {
  webhook?: string;
}
export const updateWebhookUrl = createAsyncThunk(
  'webhook/fetchWebhookUrl',
  async (webhookdata: IWebhookData) => {
    try {
      const { data } = await axios.post(
        `${configSetting.apiUrl}v1/business/webhook?url=${webhookdata.webhook}`,
        {},
        {
          headers: {
            Authorization: `Basic ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return error.message;
    }
  }
);

export const webhookSlice = createSlice({
  name: 'webhook',
  initialState,
  reducers: {
    changeWebhookUrl: (state, action) => {
      state.webhookUrl = action.payload.webhookUrl;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWebhookUrl.pending, (state, action) => {
      state.webhookStatus = 'loading';
    });
    builder.addCase(fetchWebhookUrl.fulfilled, (state, action) => {
      state.webhookStatus = 'succeeded';
      state.webhookUrl = action.payload.data;
    });
    builder.addCase(fetchWebhookUrl.rejected, (state, action) => {
      state.webhookStatus = 'failed';
      state.webhookError = action.error.message;
    });
  },
});

export const { changeWebhookUrl } = webhookSlice.actions;
export default webhookSlice.reducer;
