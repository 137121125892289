import React, { useReducer } from 'react';

import styled from '@emotion/styled';

interface IAccordion {
  title: string;
  children: any;
  handleStateChange?: any;
  index?: number;
  isOpen?: boolean;
}

// Type defination
export enum AccordionReducerActionType {
  CLICKED_SECTION = 'section-clicked',
}

interface IAccordionReducerActionType {
  type: AccordionReducerActionType;
  payload: any;
}

// Reducers
function accordionReducer(state: any, action: IAccordionReducerActionType) {
  switch (action.type) {
    case AccordionReducerActionType.CLICKED_SECTION:
      return { ...action.payload };
    default:
      return state;
  }
}

// Components

export const Accordion: React.FC<Pick<IAccordion, 'children'>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(accordionReducer, {
    index: -1,
  });

  const handleStateChange = (index: any) => {
    dispatch({
      type: AccordionReducerActionType.CLICKED_SECTION,
      payload: { index },
    });
  };

  return (
    <AccordionContainer>
      {React.Children.map(children, (child: any, index) => {
        const isOpen = index === state.index ? true : false;
        return React.cloneElement(child, {
          ...child.props,
          handleStateChange,
          index,
          isOpen,
        });
      })}
    </AccordionContainer>
  );
};

export const AccordionCell: React.FC<IAccordion> = ({
  title,
  children,
  handleStateChange,
  isOpen,
  index,
}) => {
  return (
    <div>
      <AccordionLabel onClick={() => handleStateChange(index)}>
        <Label>{title}</Label>
        {isOpen ? (
          <Img
            src="/static/svg/cancel.svg"
            className={isOpen ? 'rotate' : ''}
          />
        ) : (
          <Img src="/static/svg/plus.svg" className={isOpen ? 'rotate' : ''} />
        )}
      </AccordionLabel>
      <RevealContent className={isOpen ? 'reveal' : ''}>
        {children}
      </RevealContent>
    </div>
  );
};

// Styles
const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryText};
`;

const Img = styled.img`
  cursor: pointer;
`;

const AccordionLabel = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.8rem;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const RevealContent = styled.div`
  display: inline block;
  padding: 0;
  height: 0;
  overflow-y: auto;
  transition: all 0.2s ease;
  border-bottom: 1px solid ${({ theme }) => theme.palette.brandGrey};

  &.reveal {
    height: fit-content;
    padding-bottom: 1rem;
  }
`;
