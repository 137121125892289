import { Navigate, useRoutes } from 'react-router-dom';

import { DashboardLayout } from '../Layouts/dashboard';
import { SimpleLayout } from '../Layouts/simple/SimpleLayout';
import ForgotPassword from '../Pages/Auth/ForgotPassword';
import ForgotPasswordEmailSent from '../Pages/Auth/ForgotPasswordEmailSent';
import SignIn from '../Pages/Auth/SignIn';
import SignUp from '../Pages/Auth/SignUp';
import SignUpVerification from '../Pages/Auth/SignUpVerification';
import Home from '../Pages/Home';
import Notifications from '../Pages/Notifications';
import Orders from '../Pages/Orders';
import Page404 from '../Pages/Page404';
import Payments from '../Pages/Payments';
import Settings from '../Pages/Settings';
import Support from '../Pages/Support';
import {
  ACCOUNT,
  HOME,
  NOTFOUND,
  NOTIFICATION,
  ORDERS,
  PAYMENTS,
  RESET_PASSWORD,
  RESET_PASSWORD_VERIFY,
  SETTINGS,
  SIGNIN,
  SIGNUP,
  SUPPORT,
  VERIFY_OTP,
} from './routeConstants';

export default function Router() {
  return useRoutes([
    {
      path: HOME,
      element: <DashboardLayout />,
      children: [
        {
          path: HOME,
          element: <Home />,
        },
        {
          path: ORDERS,
          element: <Orders />,
        },
        {
          path: PAYMENTS,
          element: <Payments />,
        },
        {
          path: NOTIFICATION,
          element: <Notifications />,
        },

        {
          path: SETTINGS,
          element: <Settings />,
        },
        {
          path: SUPPORT,
          element: <Support />,
        },
      ],
    },

    {
      path: ACCOUNT,
      element: <SimpleLayout />,
      children: [
        { path: SIGNIN, element: <SignIn /> },
        { path: SIGNUP, element: <SignUp /> },
        { path: VERIFY_OTP, element: <SignUpVerification /> },
        { path: RESET_PASSWORD, element: <ForgotPassword /> },
        {
          path: RESET_PASSWORD_VERIFY,
          element: <ForgotPasswordEmailSent />,
        },
        { path: NOTFOUND, element: <Page404 /> },
        { path: '*', element: <Navigate to={`/${NOTFOUND}`} replace /> },
      ],
    },
    { path: '*', element: <Page404 /> },
  ]);
}
